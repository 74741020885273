import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../../firebase'
import Autocomplete from 'react-autocomplete'
import LoadingState from '../../../components/global/loading-state'
import Breadcrumbs from '../../../components/breadcrumbs'
import SiteFooter from '../../../components/site-footer'
import SiteHeader from '../../../components/site-header'
import SiteNav from '../../../components/site-nav'
import QuestionnaireSectionEditor from '../../../components/questionnairesectioneditor'
import Modal from '../../../components/global/modal'

//
import { compose } from 'recompose'
import withAuthorisation from '../../../components/withauthorisation'
import withUser360s from '../../../components/withuser360s'
const authCondition = (authUser) => !!authUser
//

let existing360ItemList = []

class Edit360Questionnaire extends Component {
  constructor(props) {
    super(props)

    const { match: { params } } = this.props

    let dashboardLink = "/dashboard/"
    let editLink = `/dashboard/360s/${params.id}/edit/`
    let coreSettingsLink = `/dashboard/360s/${params.id}/edit/basic/`

    this.openNewSectionModal = this.openNewSectionModal.bind(this)
    this.openCopyQuestionnaireModal = this.openCopyQuestionnaireModal.bind(this)
    this.onSubmitNewSection = this.onSubmitNewSection.bind(this)
    this.copyCompetencies = this.copyCompetencies.bind(this)
    // this.duplicateSection = this.duplicateSection.bind(this)
    this.deleteSection = this.deleteSection.bind(this);
    // this.duplicateSection = this.duplicateSection.bind(this);

    this.state = {
      loading: true,
      current360: this.props.current360,
      userDetails: this.props.userDetails,
      accountTitle: this.props.accountTitle,
      current360Id: params.id,
      accountId: params.accountId,
      questionnaireId: null,
      existing360QuestionnaireIDs: null,
      questionnaireIdToCopy: "",
      questionnaireLoaded: null,
      newSectionModalOpen: false,
      copyQuestionnaireModalOpen: false,
      duplicatingSectionId: null,
      dashboardLink: dashboardLink,
      editLink: editLink,
      coreSettingsLink: coreSettingsLink,
    }
  }
  componentDidMount() {
    window.scrollTo(0,0)

    let existing360NameAndQuestionnaireID = []

    db.doGet360s(this.state.accountId).on('value', data => {
      this.setState({
        existing360QuestionnaireIDs: data.val(),
        loading: false
      })
    })
  }

  openNewSectionModal() {
    this.setState({
      newSectionModalOpen: true
    })
  }

  openCopyQuestionnaireModal() {
    this.setState({
      copyQuestionnaireModalOpen: true
    })
  }

  onSubmitNewSection(event) {
    let questionnaireRef
    let currentForm = event.currentTarget;
    db.doCreateNewQuestionnaireSection(this.state.accountId, this.state.current360.questionnaireID, this.title.value, this.description.value).then((data) => {
      currentForm.reset()
      this.setState({
        newSectionModalOpen: false
      })
    })
    event.preventDefault();
  }





  deleteSection(e) {
    db.doDeleteSection(this.state.accountId, this.state.current360.questionnaireID, this.state.id)
    e.preventDefault()
  }

  // duplicateSection(e) {
  //   db.doDuplicateSection(this.state.current360.questionnaireID, this.state.id)
  //   e.preventDefault()
  // }

  copyCompetencies(e) {

    console.log(this.existing360.value)
    // console.log(this.state.questionnaireIdToCopy)

    db.copyCompetenciesTo360(this.state.accountId, this.existing360.value, this.state.current360.questionnaireID).then((data) => {
      this.setState({
        newSectionModalOpen: false,
        questionnaireIdToCopy: null
      })
    }).catch(error => {
      alert("cannot copy!")
      // this.setState(byPropKey('error', error))
    })

    e.preventDefault();
  }

  render() {
    // const { match: { params } } = this.props
    // let coreSettingsLink = `/dashboard/360s/${params.id}/edit/basic/`
    // alert(coreSettingsLink);
    return (
      <main>
        <SiteHeader />
        <SiteNav />
        {
          (this.state.loading) ?
            (
              <LoadingState />
            ) :
            (
              <section className="mw8-l center pa4 ph4-l">
              <Breadcrumbs
                current360={this.state.current360}
                userDetails={this.state.userDetails}
                accountTitle={this.state.accountTitle}
                accountData={this.props.accountData}
                links={[ {
                  title: "Dashboard",
                  to: "/dashboard/"
                },{
                  title: this.state.accountTitle,
                  to: "../../../../../"
                }, {
                  title: this.state.current360.title,
                  to: "../../"
                }, {
                  title: "Core Settings",
                  to: "../"
                }, {
                  title: "Questionnaire setup",
                  to: null
                } ]} />

                <h1>{ this.state.current360.title }: Questionnaire setup</h1>

                <div className="bg-white mv4 ph4 pv4 shadow-2 br2">
                  {
                    (this.state.current360.type == "Goal Tracker") ?
                      <div>
                        <p><strong>Duplicate and amend Goal Tracker steps</strong></p>

                      </div>
                     :
                      <div>
                      <p><strong>Make changes to competencies</strong></p>
                        <p>About competencies At vix ridens aliquando. Eu sit sumo vocibus, no probo docendi cum. Habeo definitiones nec cu. Hinc liberavisse et eum, an nec alia malis regione. Ea vim vide tantas utamur, mea eu praesent persecuti. Quo ea epicuri posidonium</p>
                        <div className='flex justify-between w-100'>
                          <button onClick={this.openNewSectionModal} className="f6 link ph4 pv3 dib white bg-blue br2 input-reset button-reset bn w-40">Create new competency</button>
                          <button onClick={this.openCopyQuestionnaireModal} className="f6 link ph4 pv3 dib white bg-blue br2 input-reset button-reset bn w-40">Copy existing competencies and statements</button>
                        </div>
                      </div>

                  }


                  <hr className="mv4" />

                  <QuestionnaireSectionEditor
                    accountId={ this.state.accountId }
                    current360={ this.state.current360 }
                    userDetails={ this.state.userDetails }
                    accountTitle={ this.state.accountTitle }
                    questionnaireID={ this.state.current360.questionnaireID }
                    current360Id={ this.state.current360Id }
                    current360StatusCode={this.state.current360.statusCode} />

                </div>

                <Link to={{ pathname: "../", current360: this.state.current360, userDetails: this.state.userDetails, accountTitle: this.state.accountTitle }} className="f6 link ph4 pv3 dib white bg-green br2">Return to Core Settings</Link>


                <Modal
                  isOpen={ this.state.newSectionModalOpen }
                  contentLabel="Add a new 360"
                  onClose={ () => this.setState({ newSectionModalOpen: false }) }
                  content={
                    <div>
                      <form className="mw6 center pt2 pb2" onSubmit={this.onSubmitNewSection} >
                        <label htmlFor="title" className="f7 b db mb2">Name</label>
                        <input required ref={(input) => this.title = input} aria-describedby="name-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="text" placeholder="Name your Section" />
                        <label htmlFor="description" className="f7 b db mb2">Description</label>
                        <textarea ref={(input) => this.description = input} aria-describedby="description-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" rows="6" placeholder="Give your competency a description"></textarea>
                        <button className="b ph3 pv2 mt4 input-reset ba b--black bg-transparent pointer f6 dib" type="submit">Submit</button>
                      </form>
                    </div>
                }/>




                <Modal
                  isOpen={ this.state.copyQuestionnaireModalOpen }
                  contentLabel="Add a new 360"
                  onClose={ () => this.setState({ copyQuestionnaireModalOpen: false }) }
                  content={
                    <div>
                      <form className="mw6 center pt2 pb2" onSubmit={this.copyCompetencies} >
                        <label htmlFor="existing360" className="f7 b db mb2">Select an existing 360 to copy from</label>
                        <select required ref={(input) => this.existing360 = input} aria-describedby="name-desc">
                        <option>Select a previous 360</option>
                        {
                          Object
                            .keys(this.state.existing360QuestionnaireIDs)
                            .map((key, i) => {
                              if(this.state.existing360QuestionnaireIDs[key]["questionnaireID"]) {
                                return (
                                  <option key={ this.state.existing360QuestionnaireIDs[key]["questionnaireID"] } value={ this.state.existing360QuestionnaireIDs[key]["questionnaireID"] }>{ this.state.existing360QuestionnaireIDs[key]["title"] }</option>
                                )
                              }
                            })
                        }
                        </select>
                        <button className="b ph3 pv2 mt4 input-reset ba b--black bg-transparent pointer f6 dib" type="submit">Copy competencies</button>
                      </form>
                    </div>
                }/>



              </section>

            )
        }
      </main>
    )

  }
}

//
export default compose(
  withAuthorisation(authCondition),
  withUser360s()
)(Edit360Questionnaire)
