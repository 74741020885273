import React, { Component } from 'react';
import { auth } from '../firebase';
//

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

//

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        if(email==="admin@boomerang-360.com") {
          history.push("/admin/");
        } else {
          history.push("/dashboard/");
        }
      })
      .catch(error => { this.setState(byPropKey('error', error));
      });

    event.preventDefault();

  }

  render() {
     const {
       email,
       password,
       error,
    } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form className="mw6 center pb6 pt5 ph5 bg-white s animated fadeIn" onSubmit={this.onSubmit} >
        <h1>Boomerang</h1>
        <label htmlFor="name" className="f6 b db mb2">Email Address</label>
        <input aria-describedby="name-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100 mb3" value={email} onChange={event => this.setState(byPropKey('email', event.target.value))} type="text" placeholder="Email Address" />
        <label htmlFor="password" className="f6 b db mb2">Password</label>
        <input aria-describedby="password-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100 mb3" onChange={event => this.setState(byPropKey('password', event.target.value))} type="password" placeholder="Password" />
        <button className="f6 link ph4 pv3 dib white bg-blue br2 input-reset button-reset bn w-100 mt3" disabled={isInvalid} type="submit">Login</button>
        { error && <p>{error.message}</p> }
      </form>
    );
  }
}

export default SignInForm;
