import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../../../firebase'
import SiteFooterBrand from '../../../../components/site-footer-brand'
import SiteHeader from '../../../../components/site-header'
import SiteNav from '../../../../components/site-nav'
import RelationshipForm from '../../../../components/relationship-form'
import Modal from '../../../../components/global/modal'
import withAuthorisation from '../../../../components/withauthorisation'
const authCondition = (authUser) => !!authUser
//

class Edit360Relationships extends Component {
  constructor() {
    super()

    let editLink
    let editLinkQuestionnaire
    let editLinkBrand

    this.state = {
      current360: null,
      current360Id: null,
      current360Relationships: null,
      showNewRelationshipModal: false,
      loaded360: false
    }
  }
  componentDidMount() {
    const { match: { params } } = this.props
    window.scrollTo(0,0)

    this.setState({
      current360ID: params.id
    })

    this.onSubmitRelationship = this.onSubmitRelationship.bind(this)

    // this.editLinkBasic = `/dashboard/360s/${params.id}/edit/basic/`
    this.adminLink = `/dashboard/`
    this.editLink = `/dashboard/360s/${params.id}/edit/`
    this.editLinkStandard = `/dashboard/360s/${params.id}/edit/standard/`
    this.editLinkBrand = `/dashboard/360s/${params.id}/edit/brand/`

    db.doGetCurrent360(this.state.current360ID).then((data)=> {
      if(data.val()) {
        this.setState({
          current360: data.val()
        })

        db.doGetCurrent360Relationships(this.state.current360ID).on('value', (data) => {
          if(data.val()) {
            this.setState({
              current360Relationships: data.val(),
              loaded360: true
            })
          }
        })
      }
    })



  }
  onSubmitRelationship(e) {
    let relationshipData = {
      'name': this.relationshipName.value,
      'colour': this.relationshipColour.value,
      'description': this.relationshipDescription.value
    }

    // console.log(relationshipData);
    db.doCreateNewRelationship(this.state.current360ID, this.relationshipLabel.value, relationshipData).then((data) => {
      this.setState({ showNewRelationshipModal: false })
    })

    e.preventDefault()
  }
  render() {


    return (
      <main>
        <SiteHeader />
        <SiteNav />
        {
          (this.state.loaded360) ?
            (
              <section className="mw8-l center pa4 ph4-l">
                <p><Link to={this.adminLink}>Dashboard</Link> &rarr; <Link to={this.editLink}>{ this.state.current360.title }</Link> &rarr; <Link to={this.editLinkStandard}>Standard Settings</Link> &rarr; Relationship Settings</p>

                <h1>{ this.state.current360.title }: Relationship Settings</h1>

                <div className="bg-white mv4 ph4 pv4 shadow-2 br2">
                  <p><strong>Change Relationship labels</strong></p>
                  <p>Here you can manage the labels of each of the colleague relationships - you can also add aditional relationships if required</p>
                  {
                    Object
                      .keys(this.state.current360Relationships)
                      .map((key, i) => {
                        return (
                          <RelationshipForm key={key} current360Id={this.state.current360ID} relationshipNum={i+1} relationshipKey={key} relationship={ this.state.current360Relationships[key] } />
                        )
                      })
                  }

                  <div className="w-100 flex justify-end">
                    <button className="btn" onClick={ () => this.setState({ showNewRelationshipModal: true }) }>Create new Relationship</button>
                  </div>

                </div>

                <Modal
                  isOpen={this.state.showNewRelationshipModal}
                  contentLabel="Add a new relationship"
                  onClose={ () => this.setState({ showNewRelationshipModal: false }) }
                  content={
                  <div>
                    <h2>Create a new Relationship</h2>
                    <form className="mw6 center pt2 pb2" onSubmit={this.onSubmitRelationship} >
                      <div className="pv3">
                        <label htmlFor="title" className="f6 b db mb2">Label</label>
                        <input required maxLength="3" ref={(input) => this.relationshipLabel = input} aria-describedby="name-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="text" placeholder="Eg: LM" />
                      </div>
                      <div className="pv3">
                        <label htmlFor="title" className="f6 b db mb2">Name</label>
                        <input required ref={(input) => this.relationshipName = input} aria-describedby="name-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="text" placeholder="Eg: Line Manager" />
                      </div>
                      <div className="pv3">
                        <label htmlFor="title" className="f6 b db mb2">Colour</label>
                        <input required ref={(input) => this.relationshipColour = input} aria-describedby="name-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="text" placeholder="#000000" />
                      </div>
                      <div className="pv3">
                        <label htmlFor="title" className="f6 b db mb2">Description</label>
                        <textarea className="input-reset ba b--black-20 pa2 mb2 db w-100" ref={(input) => this.relationshipDescription = input}></textarea>
                      </div>
                      <button className="b ph3 pv2 input-reset ba b--black bg-transparent pointer f6 dib" type="submit">Submit</button>
                    </form>
                  </div>
                  }
                />

              </section>
            ) :
            (
              "Loading"
            )
        }
        <SiteFooterBrand />
      </main>
    );
  }
}

//
export default withAuthorisation(authCondition)(Edit360Relationships);
