import React, { Component } from 'react'
import logo from '../logo.svg'

class SiteFooterBrand extends Component {
  render() {
    return (
      <header className='site-footer w-100 flex justify-center items-center ph3 pv4 left-0'>
        <div className="header-logo"></div>
      </header>
    );
  }
}

export default SiteFooterBrand
