import React, { Component } from 'react'
import { compose } from 'recompose'
import { db } from '../../firebase'
import { Link } from 'react-router-dom'
import LoadingState from '../../components/global/loading-state'
import Breadcrumbs from '../../components/breadcrumbs'
import SiteFooterBrand from '../../components/site-footer-brand'
import SiteHeader from '../../components/site-header'
import SiteNav from '../../components/site-nav'
import RunOptions from '../../components/runoptions'
import withAuthorisation from '../../components/withauthorisation'
import withUser360s from '../../components/withuser360s'
const authCondition = (authUser) => !!authUser


class Edit360 extends Component {
  constructor(props) {
    super(props)

    let editLinkBasic = ""

    console.log(this.props)

    this.state = {
      current360: this.props.current360,
      userDetails: this.props.userDetails,
      accountTitle: this.props.accountTitle,
      current360ID: this.props.match.params.id,
      accountId: this.props.match.params.accountId,
      loaded360: false
    }
  }
  componentDidMount() {
    window.scrollTo(0,0)
    const { match: { params } } = this.props

    this.editLinkBasic = `/dashboard/360s/${params.id}/edit/basic/`
    this.editLinkStandard = `/dashboard/360s/${params.id}/edit/standard/`

    // db.doGetCurrent360(params.id).then((data)=> {
    //   if(data.val()) {
    //     this.setState({
    //       current360: data.val(),
    //       current360ID: data.key,
    //       loaded360: true
    //     })
    //   }
    // })

  }

  render() {
    return (
      <main>
        <SiteHeader />
        <SiteNav />

        <section className="mw8-l center pa4 ph4-l">
          <Breadcrumbs
            current360={this.state.current360}
            userDetails={this.state.userDetails}
            accountTitle={this.state.accountTitle}
            accountData={this.props.accountData}
            links={[ {
              title: "Dashboard",
              to: "/dashboard/"
            },{
              title: this.state.accountTitle,
              to: "../../../"
            }, {
              title: this.state.current360.title,
              to: null
            } ]} />

          <h1>Set-up your { this.state.current360.type } project</h1>

          <div className="flex justify-between items-center w100 bg-white shadow-2 pa4 mb3 br2">
            <div className="w-70">
              <p className="ma0"><strong>Core settings</strong></p>
              <p className="ma0">You can set-up the questionnaire and brand your 360 project here</p>
            </div>
            <Link className="btn" to={{ pathname: "basic/", accountData: this.props.accountData, current360: this.state.current360, userDetails: this.state.userDetails, accountTitle: this.state.accountTitle }}>Setup Core settings</Link>
          </div>

          <div className="flex justify-between items-center w100 bg-white shadow-2 pa4 mb3 br2">
            <div className="w-70">
              <p className="ma0"><strong>Standard settings</strong></p>
              <p className="ma0">You can change general settings for your project here. For example, the rating scale or the relationship titles of those providing the feedback</p>
            </div>
            <Link className="btn" to="edit/standard">Setup Standard settings</Link>
          </div>

          {
            /*
            <div className="flex justify-between items-center w100 bg-white shadow-2 pa4 mb3 br2">
              <div className="w-70">
                <p className="ma0"><strong>Professional settings</strong></p>
                <p className="ma0">Here you can change your report settings</p>
              </div>
              <Link className="btn" to={ this.editLinkBasic }>Setup Professional settings</Link>
            </div>
            */
          }


          {
            (this.state.current360.statusCode < 1) ?
              (
                <div className="mv5">
                  <h2>Add groups to your { this.state.current360.type } project</h2>
                  <p>Please setup a questionnaire with at least two sections/compentencies before creating a new user group.</p>
                  <Link className="btn" to="basic/">Setup Core settings</Link>
                </div>
              ) : (
                <RunOptions current360ID={this.state.current360ID} current360={this.state.current360} userDetails={this.state.userDetails} accountTitle={this.state.accountTitle} accountId={this.state.accountId} />
              )
          }


        </section>

        <SiteFooterBrand />
      </main>
    )

  }
}

//
export default compose(
  withAuthorisation(authCondition),
  withUser360s()
)(Edit360)

// export default withAuthorisation(authCondition)(Edit360);
