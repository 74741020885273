import firebase from 'firebase';
import 'firebase/auth';

const prodConfig = {
  apiKey: "AIzaSyBpg0kFrXbYL4W0ETRSRxEokgxtdwEAlDs",
  authDomain: "boomerang-990c9.firebaseapp.com",
  databaseURL: "https://boomerang-990c9.firebaseio.com",
  projectId: "boomerang-990c9",
  storageBucket: "gs://boomerang-dev-5e382",
  messagingSenderId: "782029534975"
};
const devConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID
};


const config = process.env.REACT_APP_FIREBASE_DB === 'staging' || typeof process.env.REACT_APP_FIREBASE_DB === 'undefined'
  ? devConfig
  : prodConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
  auth,
};

// export const provider = new firebase.auth.GoogleAuthProvider();
// export const auth = firebase.auth();
// export default firebase;
