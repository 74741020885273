import React, { Component } from 'react';
import { auth, db } from './firebase'
import generator from 'generate-password'
import { getUnique } from '../helpers'



// User API
export const doCreateUser = (id, fname, lname, email, companyname, clientname, primarycolour, secondarycolour, tertiarycolour, clientlogo) => {
  return db.ref(`admins/${id}`).set(
    {
      "fname": fname,
      "lname": lname,
      "email": email,
      "accounts": [{
        "id": id,
        "title": `${companyname} 360 Feedback`,
      }]
    }
  ).then(() => {
    db.ref(`accounts/${id}`).set(
      {
        "settings": {
          "title": `${companyname} 360 Feedback`,
          "clientname": clientname,
          "logo": clientlogo,
          "primarycolour": primarycolour,
          "secondarycolour": secondarycolour,
          "tertiarycolour": tertiarycolour,
        },
        "360s": false,
        "questionnaires": false,
        "users": false,
        "reviewers": false,
      })
  })
}





export const doCreateNonAdminUser = (accountID, forename, surname, email) => {
  // console.log(email)
  console.log("accountID, forename, surname, email");
  console.log(accountID, forename, surname, email);
  return new Promise(function(resolve, reject) {
    if(!email) {
      reject("Email invalid")
    } else {
      db.ref(`accounts/${accountID}/users`).orderByChild(`email`).equalTo(email).once("value",snapshot => {
        const userData = snapshot.val()
        if (snapshot.exists()) {
          // User exists
          resolve(userData)
        } else {
          console.log('Creating new user - '+forename+' '+surname+' - '+email);
          db.ref(`accounts/${accountID}/users`).on('child_added', snapshot => {

            resolve(
              { [snapshot.key]: snapshot.val() }
            )
            db.ref(`accounts/${accountID}/users`).off('child_added');
          })

          db.ref(`accounts/${accountID}/users`).push({
            forename,
            surname,
            email
          })
        }
      })
    }

  })

}



export const doCreate360 = (accountID, title, clientName, new360Type) => {
  let object360 = {
    'type': new360Type,
    'title': title,
    'client': clientName,
    'createdDate': Date.now(),
    'expirationDate': null,
    'archived': false,
    'statusCode': 0,
    'relationships': {
      'DR': {
        'name': 'Direct Report',
        'colour': '#54b99c',
        'description': '',
      },
      'LM': {
        'name': 'Line Manager',
        'colour': '#dcdc00',
        'description': '',
      },
      'P': {
        'name': 'Peer',
        'colour': '#5b4898',
        'description': '',
      },
      'O': {
        'name': 'Other',
        'colour': '#1c9ad7',
        'description': '',
      },
    },
    'email_text': {
      'reminders': [
        {
          'title': 'Complete your self-review',
          'body_text': '',
          'button_text': 'Complete your self-review',
        }, {
          'title': 'Your self-review is waiting',
          'body_text': '',
          'button_text': 'Complete your self-review',
        }, {
          'title': 'Your self-review is waiting',
          'body_text': '',
          'button_text': 'Complete your self-review',
        }, {
          'title': 'Final reminder - complete your self-review',
          'body_text': '',
          'button_text': 'Complete your self-review',
        },
      ]

    }
  }

  return db.ref(`accounts/${accountID}/360s`).push(
    object360
  );
}


export const doCreateNewQuestionnaire = (accountID, new360Type) => {
  let questionnaireObj = {
    'type': new360Type
  }
  if(new360Type == "Goal Tracker") {

    let sectionObj = {
      'title': 'Goal Number 1',
      'description': 'This is the first goal',
    }

    let questionsObj = [
      {
        'answerType': "FreeText",
        'questionTitle': "My goal is",
        'required': true,
      },
      {
        'answerType': "FreeText",
        'questionTitle': "Specific actions needed to deliver my goal",
        'required': true,
      },
      {
        'answerType': "FreeText",
        'questionTitle': "I will measure my success by",
        'required': true,
      },
      {
        'answerType': "FreeText",
        'questionTitle': "The support I need",
        'required': true,
      },
      {
        'answerType': "Date",
        'questionTitle': "When will I review my progress",
        'required': true,
      },
      {
        'answerType': "FreeText",
        'questionTitle': "My progress to date",
        'required': true,
      },
      {
        'answerType': "Date",
        'questionTitle': "My deadline is",
        'required': true,
      }
    ]


    return new Promise(function(resolve, reject) {

      db.ref(`accounts/${accountID}/questionnaires`).push(questionnaireObj).then(data => {
        let questionnaire = data
        let questionnaireKey = data.key
        db.ref(`accounts/${accountID}/questionnaires/${questionnaireKey}/sections`).push(sectionObj).then(data => {
          let sectionKey = data.key
          Object
            .keys(questionsObj)
            .map(key => {
              db.ref(`accounts/${accountID}/questionnaires/${questionnaireKey}/sections/${sectionKey}/questions/`).push(questionsObj[key])
            })

          resolve(questionnaire)

        })
      }).catch((error) => {
        console.log(error);
      });

    })

  } else {


    return db.ref(`accounts/${accountID}/questionnaires`).push(
      questionnaireObj
    )
  }

  console.log(questionnaireObj);



}


export const doDeleteRelationship = (current360Id, originalKey) => {
  return db.ref(`360/${ current360Id }/relationships/`).child(originalKey).remove()
}

export const doCreateNewRelationship = (current360Id, newRelationshipKey, relationshipData) => {
  return db.ref(`360/${ current360Id }/relationships/`).child(newRelationshipKey).set(relationshipData)
}

export const doUpdateRelationship = (current360Id, originalKey, relationshipData) => {
  return db.ref(`360/${current360Id}/relationships/`).child(originalKey).update(
    relationshipData
  )
}


export const doUpdateSectionTitle = (accountID, questionnaireRef, sectionRef, newTitle) => {
  let updatedTitle = {
    'title': newTitle
  }
  return db.ref(`accounts/${accountID}/questionnaires/${questionnaireRef}/sections/`).child(sectionRef).update(
    updatedTitle
  )
}

export const doUpdateSectionDesc = (accountID, questionnaireRef, sectionRef, newDescription) => {
  let updatedDescription = {
    'description': newDescription
  }
  return db.ref(`accounts/${accountID}/questionnaires/${questionnaireRef}/sections/`).child(sectionRef).update(
    updatedDescription
  )
}

// Check user by email
export const doGetUserByEmail = (email) =>
  auth.getUserByEmail(email);

export const getUserFromGroup = (id) =>
  db.ref(`users/${id}/`).once('value')


export const doGetCurrentGroup = (accountID, current360Id, currentGroupId) =>
  db.ref(`accounts/${accountID}/360s/${current360Id}/groups/${currentGroupId}/`)

export const getQuestionnaireSections = (accountID, questionnaireId) =>
  db.ref(`accounts/${accountID}/questionnaires/${questionnaireId}/sections/`)

export const doCreate360Group = (accountID, current360Id, title, users) => {

  let oneWeekFromNow = new Date()
  oneWeekFromNow.setDate(oneWeekFromNow.getDate()+7)



  let group = {}
  group["title"] = title
  group["statusCode"] = 0
  group["activeTask"] = 1
  group["createdDate"] = Date.now()
  group["tasks"] = {
    "1": {
      "dueDate": Date.parse(oneWeekFromNow),
      "completedDate": false,
      "user": false,
    },
    "2": {
      "dueDate": false,
      "completedDate": false,
      "user": false,
    },
    "3": {
      "dueDate": false,
      "completedDate": false,
      "user": false,
    },
    "4": {
      "dueDate": false,
      "completedDate": false,
      "user": false,
    },
  }

  group["users"] = {}

  if(users) {
    let usersComplete = false

    // user GetUnique to remove possible duplicate email addresses / users
    let usersToParse = getUnique(users, 'Email');

    let checkComplete = () => {
      if(Object.keys(group["users"]).length == usersToParse.length) {
        return true
      } else {
        return false
      }
    }

    usersToParse.map((parsedUser, i, arr) => {
      doCreateNonAdminUser(accountID, parsedUser['Forename'], parsedUser['Surname'], parsedUser['Email']).then((userData) => {
        Object
          .keys(userData)
          .map(key => {
            let groupUserData = {
              "email": userData[key]["email"],
              "forename": userData[key]["forename"],
              "surname": userData[key]["surname"],
              "reviewers": false,
            }

            group["users"][key] = groupUserData
          })

        // console.log( checkComplete() );

        if( checkComplete() ) {
          return db.ref(`accounts/${accountID}/360s/${current360Id}/groups/`).push(
            group
          )
        }

      })

    })

  } else {
    return db.ref(`accounts/${accountID}/360s/${current360Id}/groups/`).push(
      group
    )
  }

}


export const addNewUserToGroup = (accountID, userData, current360Id, currentGroupId) => {
  let newUser = {}
  let newUserID

  Object
    .keys(userData)
    .map(key => {
      newUserID = key
      let groupUserData = {
        "email": userData[key]["email"],
        "forename": userData[key]["forename"],
        "surname": userData[key]["surname"],
        "reviewers": false,
      }

      newUser[key] = groupUserData
    })

  return new Promise(function(resolve, reject) {
    db.ref(`accounts/${accountID}/360s/${current360Id}/groups/${currentGroupId}/users/${newUserID}/`).once("value", snapshot => {
      if (snapshot.exists()){
        reject("User already exists in this Group.")

      } else {
        db.ref(`accounts/${accountID}/360s/${current360Id}/groups/${currentGroupId}/`).child(`users`).update(
          newUser, () => {
            resolve()
          }
        )
      }
    });
  })



  // return db.ref(`360/${current360Id}/groups/${currentGroupId}/`).child(`users`).update(
  //   newUser
  // )


  // return db.ref(`360/${current360Id}/groups/${currentGroupId}/users/`).push(
  //   userData
  // )
}


export const assign360ToUser = (accountID, userID, current360Id, currentGroupId) => {

  return doGet360QuestionniareID(accountID, current360Id).then((data) => {

    let questionnaireID = data.val()

    let blankQuestionnaireObj = {
      'completed': false,
      'completionPercentage': 0,
      'sections': false
    }

    // let obj[questionnaireID] = blankQuestionnaireObj

    return db.ref(`accounts/${accountID}/users/${userID}/360s/${current360Id}/group/${currentGroupId}/questionnaires/${questionnaireID}/self-review/`).set(
      blankQuestionnaireObj
    )
  })


}

export const doCreateNewQuestion = (accountID, questionnaireRef, sectionRef, questionData) => {
  return db.ref(`accounts/${accountID}/questionnaires/${questionnaireRef}/sections/${sectionRef}/questions`).push(
    questionData
  )
}

export const updateQuestion = (questionnaireRef, sectionRef, questionRef, questionData) => {
  return db.ref(`questionnaires/${questionnaireRef}/sections/${sectionRef}/questions`).child(questionRef).update(
    questionData
  )
}


export const updateGroupTaskCompleted = (current360ID, groupID, taskNumber, userEmail) => {
  let updatedTask = {
    'completedDate': Date.now(),
    'user': userEmail
  }
  // console.log(userEmail);
  return db.ref(`360/${current360ID}/groups/${groupID}/tasks/`).child(taskNumber).update(
    updatedTask
  )
}

export const updateGroupActiveTask = (current360ID, groupID, reminderNumber) => {
  console.log(current360ID, groupID, reminderNumber)
  let updatedActiveTask = {
    'activeTask': reminderNumber
  }
  return db.ref(`360/${current360ID}/groups/`).child(groupID).update(
    updatedActiveTask
  )
}

export const updateNextTaskDueDate = (current360ID, groupID, reminderNumber) => {
  let oneWeekFromNow = new Date()
  oneWeekFromNow.setDate(oneWeekFromNow.getDate()+7)

  console.log(current360ID, groupID, reminderNumber)
  let updatedDueDate = {
    'dueDate': Date.parse(oneWeekFromNow)
  }
  return db.ref(`360/${current360ID}/groups/${groupID}/tasks/`).child(reminderNumber).update(
    updatedDueDate
  )
}

export const update360StatusCode = (accountID, current360Id, statusCode) => {
  let updatedStatusCode = {
    'statusCode': statusCode
  }
  console.log(accountID, current360Id, statusCode)
  return db.ref(`accounts/${accountID}/360s`).child(`${current360Id}`).update(
    updatedStatusCode
  )
}



export const doCreateNewQuestionnaireSection = (accountID, questionnaireRef, sectionTitle, sectionDescription) => {
  let newSectionTitle = {}
  newSectionTitle["title"] = sectionTitle
  newSectionTitle["description"] = sectionDescription
  newSectionTitle["questions"] = {}

  return db.ref(`accounts/${accountID}/questionnaires/${questionnaireRef}/sections`).push(
    newSectionTitle
  )
}

export const doDuplicateQuestionnaireSection = (accountID, questionnaireID, sectionToCopy, sectionTitle, sectionDescription) => {
  let newSectionObj = {}
  newSectionObj["title"] = sectionTitle
  newSectionObj["description"] = sectionDescription
  newSectionObj["questions"] = {}



  return new Promise(function(resolve, reject) {
    db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/sections/${sectionToCopy}`).once("value", snapshot => {
      if (snapshot.exists()) {
        let questionsToCopy = snapshot.val().questions
        db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/sections`).push(
          newSectionObj
        ).then((data) => {
          let newSectionKey = data.key
          if(questionsToCopy) {
            Object
              .keys(questionsToCopy)
              .map(key => {
                db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/sections/${newSectionKey}/questions/`).push(questionsToCopy[key])
              })
          }

          resolve()
        })
      } else {
        reject("cannot copy section")
      }
    })
  })


  // return db.ref(`questionnaires/${questionnaireRef}/sections`).push(
  //   newSectionTitle
  // )
}

export const doAssignQuestionnaireTo360 = (accountID, questionnaireRef, current360Id) => {
  // Bind questionnaire to current 360
  return db.ref(`accounts/${accountID}/360s/${current360Id}/questionnaireID`).set(
    questionnaireRef
  )
}

export const copyCompetenciesTo360 = (accountID, questionnaireIdToCopy, questionnaireIdDestination) => {
  return new Promise(function(resolve, reject) {
    db.ref(`accounts/${accountID}/questionnaires/${questionnaireIdToCopy}`).once("value", snapshot => {
      if (snapshot.exists()) {
        db.ref(`accounts/${accountID}/questionnaires/${questionnaireIdDestination}`).set(
          snapshot.val()
        ).then(() => {
          resolve()
        })
      } else {
        reject("no questionnaire setup for this 360")
      }
    })
  })

}

export const onceGetUsers = () =>
  db.ref('users').once('value');

export const doGet360s = (accountID) =>
  db.ref(`accounts/${accountID}/360s`)

export const doGetCurrent360 = (accountID, current360ID) =>
  db.ref(`accounts/${accountID}/360s/${current360ID}`)

export const doGetClients = () =>
  db.ref('clients')

export const doGet360QuestionniareID = (accountID, current360ID) =>
  db.ref(`accounts/${accountID}/360s/${current360ID}/questionnaireID`).once('value')

// export const doGetCurrent360 = (current360ID) =>
//   db.ref(`360/${current360ID}/`).once('value')

export const doDeleteQuestion = (accountID, questionnaireID, sectionID, questionID) =>
  db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/sections/${sectionID}/questions/`).child(questionID).remove();

export const doDeleteSection = (accountID, questionnaireID, sectionID) =>
  db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/sections/`).child(sectionID).remove();

// export const doDuplicateSection = (questionnaireID, sectionID) => {
//   return new Promise(function(resolve, reject) {
//     db.ref(`questionnaires/${questionnaireID}/sections/${sectionID}`).once("value", snapshot => {
//       if (snapshot.exists()) {
//         console.log(snapshot.val().questions)
//         // db.ref(`questionnaires/${questionnaireID}/sections`).push(
//         //   snapshot.val()
//         // ).then(() => {
//         //   resolve()
//         // })
//       } else {
//         reject("cannot copy section")
//       }
//     })
//   })
// }

export const doDelete360 = (accountId, current360Id) =>
  db.ref(`accounts/${accountId}/360s`).child(current360Id).remove();

export const doArchive360 = (accountId, current360Id) => {
  return db.ref(`accounts/${accountId}/360s/${current360Id}/archived`).set(
    true
  )
}


export const doGetCurrent360Relationships = (current360ID) =>
  db.ref(`360/${current360ID}/relationships/`)


export const doGetSectionQuestions = (accountID, questionnaireID, sectionID) =>
  db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/sections/${sectionID}`)


export const doGetUserGroups = (accountID, current360ID) =>
  db.ref(`accounts/${accountID}/360s/${current360ID}/groups/`)

export const doGetClientLogo = (accountID, current360ID) => {
  return new Promise(function(resolve, reject) {
    db.ref(`accounts/${accountID}/360s/${current360ID}/brand/logo`).once("value", snapshot => {
      if(snapshot.val()) {
        resolve(snapshot.val())
      } else {
        db.ref(`accounts/${accountID}/settings/logo`).once("value", snapshot => {
          if(snapshot.val()) {
            resolve(snapshot.val())
          } else {
            reject("no logo found")
          }
        })
      }
    })
  })
}


export const doGetSectionsList = (accountID, questionnaireID) => {
  return db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/`)
}

export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

export const doGetAnswers = (accountID, userID, current360ID, groupID, questionnaireID, reviewType) =>
  db.ref(`accounts/${accountID}/users/${userID}/360s/${current360ID}/group/${groupID}/questionnaires/${questionnaireID}/${reviewType}/`)

export const doGetCompletedReviewers = (current360Id, groupId, userId) =>
  db.ref(`360s/${current360Id}/groups/${groupId}/users/${userId}/reviewers/`).orderByChild(`completed`).equalTo(true).once('value');

export const doGetReviewers = (current360Id, groupId, userId) =>
  db.ref(`360s/${current360Id}/groups/${groupId}/users/${userId}/reviewers/`).once('value');

export const doGetReviewerAnswers = (reviewerID) =>
  db.ref(`reviewers/${reviewerID}/review/`).once('value')

export const doGetAccountBranding = (accountID) =>
  db.ref(`accounts/${accountID}/settings/`).once('value')



export const doGetLoggedInUserDetails = (uid) =>
  db.ref(`admins/${uid}`).once('value')
