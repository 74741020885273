import React, { Component } from 'react'
import reactCSS from 'reactcss'
import { auth, db, storage } from '../firebase'
import firebase from 'firebase'
import { signup } from '../firebase/signup'
import FileUploader from "react-firebase-file-uploader"
import { BlockPicker } from 'react-color'
import Modal from '../components/global/modal'
//

const INITIAL_STATE = {
  fname: '',
  lname: '',
  email: '',
  clientname: '',
  companyname: '',
  primarycolour: '#0051ff',
  secondarycolour: '#3f527f',
  tertiarycolour: '#0152fe',
  passwordOne: '',
  passwordTwo: '',
  displayPrimaryColourPicker: false,
  displaySecondaryColourPicker: false,
  displayTerColourPicker: false,
  isUploading: false,
  progress: false,
  clientlogo: "https://firebasestorage.googleapis.com/v0/b/boomerang-dev-5e382.appspot.com/o/boomerang-logo.png?alt=media&token=3b0fae4b-3275-400b-9900-e3640cd5ec66",
  clientlogoimg: false,
  error: null,
  showUserCreationModal: false,
  userCreated: false,
};

const RESET_BRAND_COLOURS = {
  primarycolour: '#FFFFFF',
  secondarycolour: '#000000',
  tertiarycolour: '#3f527f',
}

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

//

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      fname,
      lname,
      email,
      companyname,
      clientlogo,
      clientname,
      primarycolour,
      secondarycolour,
      tertiarycolour,
      passwordOne,
    } = this.state;

    const {
      history,
    } = this.props;

    this.setState({
      showUserCreationModal: true
    })

    // HACK: Create new firebase app to create username/pass
    var authApp = firebase.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID
    }, 'authApp');
    var detachedAuth = authApp.auth();
    // detachedAuth.createUserWithEmailAndPassword('foo@example.com', 'asuperrandompassword');

    detachedAuth.createUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create user in DB
        db.doCreateUser(authUser.user.uid, fname, lname, email, companyname, clientname, primarycolour, secondarycolour, tertiarycolour, clientlogo)
          .then(() => {
            this.setState(() => ({ ...INITIAL_STATE, userCreated: true }));
            // history.push("/dashboard/");

            // console.log(email, passwordOne)

            // detachedAuth.delete()


            this.sendWelcomeEmail(`${fname} ${lname}`, email, passwordOne, clientlogo)
          })
          .catch(error => {
            this.setState(byPropKey('error', error));
            this.setState({
              showUserCreationModal: false
            })
          });

      })
      .catch(error => {
        this.setState(byPropKey('error', error));
        this.setState({
          showUserCreationModal: false
        })
      });

    event.preventDefault();

  }


  sendWelcomeEmail = (name, useremail, pass, clientlogo) => {

    let selfReviewLink = `https://admin.boomerang-360.com/`
    let emailTitle = `Welcome to Boomerang`
    let selfReviewButtonText = `Click here to Login to your account`
    let emailBodyCopy = `A new account has been setup for you!<br/><br/><span style="font-size:50%; line-height:1em;">Your Username<br/><strong>${useremail}</strong><br/><br/>Your Password<br/><strong>${pass}</strong></span>`

    // console.log(process.env.REACT_APP_EMAILJS_WELCOME_TEMPLATE_ID)


    window.emailjs.send('mailgun', process.env.REACT_APP_EMAILJS_WELCOME_TEMPLATE_ID, {
      senderEmail: `no-reply@boomerang-360.com`,
      receiverEmail: useremail,
      selfReviewButtonText: selfReviewButtonText,
      selfReviewLink: selfReviewLink,
      emailTitle: emailTitle,
      emailBodyCopy: emailBodyCopy,
      clientLogo: INITIAL_STATE.clientlogo,
    }).then(res => {
        console.log("SENT")
      }).catch(err => {
        // Handle errors here however you like, or use a React error boundary
        console.error('Failed to send feedback. Error: ', err)
      })


  }

  resetBrandColours = (e) => {
    console.log(e.target.checked);
    if(e.target.checked) {
      this.setState({
        primarycolour: RESET_BRAND_COLOURS.primarycolour,
        secondarycolour: RESET_BRAND_COLOURS.secondarycolour,
        tertiarycolour: RESET_BRAND_COLOURS.tertiarycolour,
      })
    } else {
      this.setState({
        primarycolour: INITIAL_STATE.primarycolour,
        secondarycolour: INITIAL_STATE.secondarycolour,
        tertiarycolour: INITIAL_STATE.tertiarycolour,
      })

    }
  }


  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({ clientlogoimg: filename, progress: 100, isUploading: false });
    storage.doGetUploadedImageURL(filename).then((url) => {
      this.setState({ clientlogo: url })
    })

  };

  render() {
     const {
      fname,
      lname,
      email,
      companyname,
      clientname,
      clientlogo,
      passwordOne,
      passwordTwo,
      primarycolour,
      secondarycolour,
      tertiarycolour,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      companyname === '' ||
      fname === '' ||
      lname === '';

      const pickerStyles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
          primarycolorinput: {
            background: primarycolour,
            color: '#FFF'
          },
          secondarycolorinput: {
            background: secondarycolour,
            color: '#FFF'

          },
          tertiarycolorinput: {
            background: tertiarycolour,
            color: '#FFF'

          },
        },
      });

    return (
      <form className="mw7 center flex flex-wrap justify-between bg-white pa4" onSubmit={this.onSubmit} >
        <input type="hidden" value="prayer" />
        <div className="w-40 mv2">
          <label htmlFor="fname" className="f6 b db mb2">First Name</label>
          <input autoComplete="off" aria-describedby="fname-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={fname} onChange={event => this.setState(byPropKey('fname', event.target.value))} type="text" placeholder="First Name" />
        </div>
        <div className="w-50 mv2">
          <label htmlFor="lname" className="f6 b db mb2">Surname</label>
          <input aria-describedby="lname-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={lname} onChange={event => this.setState(byPropKey('lname', event.target.value))} type="text" placeholder="Surname" />
        </div>

        <div className="w-100 mv2">
          <label htmlFor="email" className="f6 b db mb2">Email Address</label>
          <input aria-describedby="email-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={email} onChange={event => this.setState(byPropKey('email', event.target.value))} type="text" placeholder="Email Address" />
        </div>

        <div className="w-100 mv2">
          <label htmlFor="companyname" className="f6 b db mb2">Company Name</label>
          <input aria-describedby="companyname-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={companyname} onChange={event => this.setState(byPropKey('companyname', event.target.value))} type="text" placeholder="Company Name" />
        </div>

        <div className="w-40 mv2">
          <label htmlFor="password" className="f6 b db mb2">Password</label>
          <input autoComplete="new-password" aria-describedby="password-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={passwordOne} onChange={event => this.setState(byPropKey('passwordOne', event.target.value))} type="password" placeholder="Password" />
        </div>

        <div className="w-40 mv2">
          <label htmlFor="passwordrepeat" className="f6 b db mb2">Confirm Password</label>
          <input autoComplete="new-password" aria-describedby="passwordrepeat-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={passwordTwo} onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))} type="password" placeholder="Confirm Password" />
        </div>

        <div className="w-100 mv3 pv3 ph4 bg-light-blue">
          <h2>Optional account customisation</h2>

          <div className="w-100 mv3">
            <label htmlFor="clientlogo" className="f6 b db">Client Logo</label>
            <p className="mb2 mt0"><small>* Images should be uploaded at 400px x 112px. Images are cropped (not scaled) down.</small></p>
            <input aria-describedby="email-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={clientlogo} type="hidden" />

            {clientlogo && <img width="140" src={clientlogo} />}

            <div className="w-100 mv3 mb1">
              <FileUploader
                accept="image/*"
                name="clientlogoimg"
                randomizeFilename
                storageRef={storage.doGetImageRef()}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </div>
          </div>


          <label htmlFor="resetbrand" className="f6 b db mv4"><input aria-describedby="resetbrand-desc" id="resetbrand" type="checkbox" onChange={this.resetBrandColours} /> Reset Boomerang Brand colours</label>



          <div className="w-100 mb3 flex flex-wrap justify-between">

            <div className="w-25">
              <label htmlFor="primarycolour" className="f6 b db mb2">Primary Colour</label>
              <input aria-describedby="primarycolour-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" defaultValue={primarycolour} style={ pickerStyles.primarycolorinput } onClick={ (e) => { this.setState({ displayPrimaryColourPicker: true }) } } type="text" />
              { this.state.displayPrimaryColourPicker ? <div style={ pickerStyles.popover }>
                <div style={ pickerStyles.cover } onClick={ (e) => { this.setState({ displayPrimaryColourPicker: false }) } }/>
                <BlockPicker color={ primarycolour } onChange={color => this.setState(byPropKey('primarycolour', color.hex))} />
              </div> : null }
              <p className="mt0"><small>* Header BG Colour</small></p>
            </div>

            <div className="w-25">
              <label htmlFor="secondarycolour" className="f6 b db mb2">Secondary Colour</label>
              <input aria-describedby="secondarycolour-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" defaultValue={secondarycolour} style={ pickerStyles.secondarycolorinput } onClick={ (e) => { this.setState({ displaySecondaryColourPicker: true }) } } type="text" />
              { this.state.displaySecondaryColourPicker ? <div style={ pickerStyles.popover }>
                <div style={ pickerStyles.cover } onClick={ (e) => { this.setState({ displaySecondaryColourPicker: false }) } }/>
                <BlockPicker color={ secondarycolour } onChange={color => this.setState(byPropKey('secondarycolour', color.hex))} />
              </div> : null }
              <p className="mt0"><small>* Body Text Colour</small></p>
            </div>

            <div className="w-25">
              <label htmlFor="tertiarycolour" className="f6 b db mb2">Tertiary Colour</label>
              <input autoComplete="off" aria-describedby="tertiarycolour-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" defaultValue={tertiarycolour} style={ pickerStyles.tertiarycolorinput } onClick={ (e) => { this.setState({ displayTertiaryColourPicker: true }) } } type="text" />
              { this.state.displayTertiaryColourPicker ? <div style={ pickerStyles.popover }>
                <div style={ pickerStyles.cover } onClick={ (e) => { this.setState({ displayTertiaryColourPicker: false }) } }/>
                <BlockPicker color={ tertiarycolour } onChange={color => this.setState(byPropKey('tertiarycolour', color.hex))} />
              </div> : null }
              <p className="mt0"><small>* Titles Text Colour</small></p>
            </div>

          </div>

          <label htmlFor="clientname" className="f6 b db">Global Client Name</label>
          <p className="mb2 mt0"><small>* Setting a client name will prevent new accounts creating new 360s for different clients</small></p>
          <input aria-describedby="email-desc" className="input-reset ba b--black-20 pa2 mb1 db w-100" value={clientname} onChange={event => this.setState(byPropKey('clientname', event.target.value))} type="text" placeholder="Optional Fixed Client Name" />




        </div>



        <div className="w-100 mv2">
          <button className="b ph3 pv2 mt4 input-reset ba b--black bg-transparent pointer f6 dib" disabled={isInvalid} type="submit">Submit</button>
          { error && <h3 className="red">{error.message}</h3> }
        </div>

        <Modal
          isOpen={ this.state.showUserCreationModal }
          contentLabel="Delete this 360"
          onClose={ () => this.setState({ showUserCreationModal: false }) }
          content={
          <div>
            <h2>Creating New Boomerang Account</h2>
            <p>User will be notified of account details via Email when account is created</p>
            {
              (this.state.userCreated) ? (
                <button className="btn" onClick={ () => this.setState({ showUserCreationModal: false, userCreated: false }) }>Close</button>
              )

              : ''
            }
          </div>
        }/>
      </form>
    );
  }
}

export default SignUpForm;
