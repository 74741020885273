import { storage } from './firebase';

// Image ref
export const doGetImageRef = () =>
  storage.ref("images")

export const doGetUploadedImageURL = (filename) =>
  storage.ref("images")
  .child(filename)
  .getDownloadURL()
