import React, { Component } from 'react';
import { db } from '../firebase'
import { Link } from 'react-router-dom'
import Dropdown from './global/dropdown'
import Modal from './global/modal'
import { lightBoxStyles } from '../constants/lightbox.js'

const alternateActions = [
  {
    id: 0,
    title: 'Duplicate',
    selected: false,
    key: 'alternateAction'
  },
  {
    id: 1,
    title: 'Archive',
    selected: false,
    key: 'alternateAction'
  },
  {
    id: 2,
    title: 'Delete',
    selected: false,
    key: 'alternateAction'
  }
]

class List360 extends Component {
  constructor() {
    super()
    this.state = {
      deleting: false
    }
    this.delete360 = this.delete360.bind(this)
    this.confirmDeletion = this.confirmDeletion.bind(this)
    this.duplicate360 = this.duplicate360.bind(this)
    this.archive360 = this.archive360.bind(this)
    this.confirmArchive = this.confirmArchive.bind(this)
    this.fireAlternateAction = this.fireAlternateAction.bind(this)
  }
  delete360(e) {
    this.setState({
      deleting: true
    })
  }
  confirmDeletion(e) {
    db.doDelete360(this.props.accountId, this.props.index)
    e.preventDefault()
  }
  duplicate360() {
    console.log("duplicate360");
  }
  archive360() {
    this.setState({
      archiving: true
    })
  }
  confirmArchive(e) {
    db.doArchive360(this.props.accountId, this.props.index)
    e.preventDefault()
  }
  fireAlternateAction(id, key) {
    let temp = JSON.parse(JSON.stringify(alternateActions));
    temp.forEach(item => item.selected = false);
    temp[id].selected = true;

    switch(id) {
      case 0:
        this.duplicate360()
        break
      case 1:
        this.archive360()
        break
      case 2:
        this.delete360()
        break
      default:
        return;
    }

  }
  render() {
    const { current360, index, selectedAccount, userDetails, accountTitle } = this.props;

    // let createdDate = current360.createdDate
    var createdDate = new Date(current360.createdDate);
    var createdDay = createdDate.getDate();
    var createdMonth = createdDate.getMonth();
    var createdYear = createdDate.getFullYear();



    let numActiveGroups = 0
    let numIncompleteGroups = 0
    let numCompletedGroups = 0

    let editLink = `/dashboard/${selectedAccount}/360s/${index}/edit/`

    if(current360.groups) {
      let numGroups = Object.keys(current360.groups).length
      let numGroupsText = (Object.keys(current360.groups).length > 1) ? 's' : ''

      Object
        .keys(current360.groups)
        .map(key => {
          // console.log(current360.groups[key].statusCode)
          if(current360.groups[key].statusCode != null) {
            if(current360.groups[key].statusCode == 0) {
              numActiveGroups++
            } else {
              numCompletedGroups++
            }
          } else {
            numIncompleteGroups++
          }
        })
    }



    let numActiveGroupsText = (numActiveGroups > 1) ? 's' : ''
    let numIncompleteGroupsText = (numIncompleteGroups > 1) ? 's' : ''
    let numCompletedGroupsText = (numCompletedGroups > 1) ? 's' : ''

    let activeStatusText = `${numActiveGroups} active group${numActiveGroupsText}.${ (current360.statusCode == 1) ? ' Instructions not sent.' : '' }`
    let incompleteStatusText = `${numIncompleteGroups} incomplete group${numIncompleteGroupsText}.`
    let completedStatusText = `${numCompletedGroups} complete group${numCompletedGroupsText}.`


    let statusText = `${ (numActiveGroups > 0) ? activeStatusText : 'No User groups setup' }
        ${ (numIncompleteGroups > 0) ? incompleteStatusText : '' }
          ${ (numCompletedGroups > 0) ? completedStatusText : '' }`

    if(current360.statusCode === 0) {
      statusText = "No valid questionnaire setup"
    }

    return (
      <div className="flex relative items-center justify-between w100 bg-white shadow-2 pa3 mv4 br2">
        <div className="w-40">
          <p className="ma0"><strong>{ current360.title }</strong><br/>{ statusText }</p>
        </div>
        <div className="w-60 bl b--silver flex justify-between items-center pl4">
          <div>
            <p className="ma0 tc">Created {createdDay}/{createdMonth+1}/{createdYear}</p>
          </div>
          <div>
          <Dropdown
            title="Alternate actions"
            list={alternateActions}
            setTitleOnSelect={false}
            resetThenSet={this.fireAlternateAction}
          />
          </div>
          <div>

          <p className="ma0 tr"><Link className="btn" to={{ pathname: editLink, current360: current360, userDetails: userDetails, accountTitle: accountTitle }}>Edit this project</Link></p>

          </div>
        </div>

        <Modal
          isOpen={ this.state.deleting }
          contentLabel="Delete this 360"
          onClose={ () => this.setState({ deleting: false }) }
          content={
          <div>
            <h2>Are you sure you want to delete this 360?</h2>
            <p>Click confirm below to remove forever</p>
            <button onClick={this.confirmDeletion} className="btn">Confirm</button>
          </div>
        }/>

        <Modal
          isOpen={ this.state.archiving }
          contentLabel="Delete this 360"
          onClose={ () => this.setState({ archiving: false }) }
          content={
          <div>
            <h2>Are you sure you want to archive this 360?</h2>
            <p>Click confirm below to move to { current360.title } the Archive</p>
            <button onClick={this.confirmArchive} className="btn">Confirm</button>
          </div>
        }/>


      </div>
    );
  }
}
//

export default List360
