import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
// import 'firebase/storage';

//
const prodConfig = {
  apiKey: "AIzaSyBpg0kFrXbYL4W0ETRSRxEokgxtdwEAlDs",
  authDomain: "boomerang-990c9.firebaseapp.com",
  databaseURL: "https://boomerang-990c9.firebaseio.com",
  projectId: "boomerang-990c9",
  storageBucket: "gs://boomerang-dev-5e382",
  messagingSenderId: "782029534975"
};
const devConfig = {
  apiKey: "AIzaSyAvRH5ApUdhrmmLXtKr1ZgwYWbGWQLWmZg",
  authDomain: "boomerang-dev-5e382.firebaseapp.com",
  databaseURL: "https://boomerang-dev-5e382.firebaseio.com",
  projectId: "boomerang-dev-5e382",
  storageBucket: "gs://boomerang-dev-5e382",
  messagingSenderId: "41430056383"
};

const config = process.env.REACT_APP_FIREBASE_DB === 'staging' || typeof process.env.REACT_APP_FIREBASE_DB === 'undefined'
  ? devConfig
  : prodConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();

export {
  db,
  auth,
  storage,
};

// export const provider = new firebase.auth.GoogleAuthProvider();
// export const auth = firebase.auth();
// export default firebase;
