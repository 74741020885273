import React, { Component } from 'react'
import { db } from '../firebase'
import { Link } from 'react-router-dom'
import { convertTaskNames } from '../helpers'
import RevieweeDetails from '../components/revieweedetails'
import Papa from 'papaparse'
import Modal from './global/modal'
import ReactModal from 'react-modal'
import exampleUsersCSV from '../users.csv'
import withAuthorisation from '../components/withauthorisation'
import ExportGroupButton from '../components/exportgroupbutton'

const authCondition = (authUser) => !!authUser

class RunOptions extends Component {
  constructor(props) {
    super(props)

    this.openGroupModal = this.openGroupModal.bind(this)
    this.closeGroupModal = this.closeGroupModal.bind(this)
    this.onSubmitGroup = this.onSubmitGroup.bind(this)
    this.createEmptyGroup = this.createEmptyGroup.bind(this)
    this.createGroupFromCSV = this.createGroupFromCSV.bind(this)

    this.state = {
      clientLogo: "",
      loading: true,
      sendingEmails: false,
      showNewGroupModal: false,
      currentGroupId: "",
      userGroups: {},
      questionnaireID: null
    }
  }

  sendSelfReviewEmail(templateId, senderEmail, receiverEmail, current360Title, selfReviewLink, reminderNumber) {

    window.emailjs.send('mailgun', templateId, {
      senderEmail,
      receiverEmail: receiverEmail,
      selfReviewLink: selfReviewLink,
      current360Title: current360Title,
    }).then(res => {
      console.log("SENT");
    })
    // Handle errors here however you like, or use a React error boundary
    .catch(err => console.error('Failed to send feedback. Error: ', err))
  }
  componentDidMount() {
    // db.doGetUserGroups(this.props.current360ID).then((data) => {
    db.doGetClientLogo(this.props.accountId, this.props.current360ID).then((logo) => {
      this.setState({ clientLogo: logo });
    })
    db.doGet360QuestionniareID(this.props.accountId, this.props.current360ID).then((data) => {
      if(data.val()) {
        this.setState({
          questionnaireID: data.val()
        })
      }
      db.doGetUserGroups(this.props.accountId, this.props.current360ID).on('value', data => {
        if(data.val()) {
          this.setState({
            userGroups: data.val(),
            loading: false
          })
        }
      })
    })
  }
  openGroupModal() {
    this.setState({ showNewGroupModal: true });
  }
  closeGroupModal() {
    this.setState({ showNewGroupModal: false });
  }



  createGroupFromCSV(groupTitle, users) {
    console.log("createGroupFromCSV");
    // alert(this.props.current360ID)
    // console.log(db.doCreate360Group(this.props.current360ID, groupTitle, users));
    db.doCreate360Group(this.props.accountId, this.props.current360ID, groupTitle, users).on('value', data => {
      this.setState({ showNewGroupModal: false })
      this.props.history.push( `/dashboard/${this.props.accountId}/360s/${ this.props.current360ID }/edit/group/${ data.key }/` )
    })
  }

  createEmptyGroup(groupTitle) {
    db.doCreate360Group(this.props.accountId, this.props.current360ID, groupTitle, null).on('value', data => {
      this.setState({ showNewGroupModal: false })
      this.props.history.push( `/dashboard/${this.props.accountId}/360s/${ this.props.current360ID }/edit/group/${ data.key }/` )
    })
    // let groupEditLink = `/dashboard/360s/${ this.props.current360ID }/group/${ key }/`
  }

  onSubmitGroup(e) {
    let groupTitle = this.title.value
    if(!this.filesInput.files[0]) {
      // Create empty Group
      this.createEmptyGroup(groupTitle)

    } else {
      // Parse users from SVG + create Group
      Papa.parse(this.filesInput.files[0], {
        complete: (result) => {
          console.log(result.data)
          this.createGroupFromCSV(groupTitle, result.data)
        },
        skipEmptyLines: "greedy",
        header: true
      });
    }


    e.preventDefault()

  }
  render() {
    const popupboxConfig = {
      titleBar: {
        enable: false,
      },
      fadeIn: true,
      fadeInSpeed: 500,
      overlayClose: true,
    }

    return (
      <div className="mv5">

        <h2>Add groups to your { this.props.current360Type } project</h2>
        <p>You can add multiple groups/cohorts of reviewees within each 360 project. There is no restriction to the number of groups you can add to each 360 project, and each group will have its own deadline.</p>
        <p>A participant (reviewee) can only be in one group within a 360 project, but may be added to multiple 360 projects.</p>



        <div className="w100 bg-white shadow-2 pa4 mv4">
          <p><strong>View/Edit your groups:</strong></p>
          {
            (!this.state.loading) ?

            Object
              .keys(this.state.userGroups)
              .reverse()
              .map(key => {
                let groupEditLink = `/dashboard/360s/${ this.props.current360ID }/group/${ key }/`
                return (
                  <div key={key} className="w-100 bg-light-blue flex justify-between pa4 mb4">
                    <p><strong>{this.state.userGroups[key].title}</strong></p>
                    <Link className="btn" to={{ pathname: `group/${ key }/`, current360: this.state.current360, userDetails: this.state.userDetails, accountTitle: this.state.accountTitle }}>Edit Group</Link>
                  </div>
                )
              })

            : ''

          }
          <div className="w-100">
            <button className="btn" onClick={ this.openGroupModal }>Add a new group</button>
          </div>
        </div>



        <Modal
          isOpen={this.state.showNewGroupModal}
          contentLabel="Add a new group"
          onClose={ () => this.setState({ showNewGroupModal: false }) }
          content={
          <div>
            <h2>Create a new Group</h2>
            <form className="mw6 center pt2 pb2" onSubmit={this.onSubmitGroup} >
              <div className="pv3">
                <label htmlFor="title" className="f6 b db mb2">Add group/cohort name</label>
                <input required ref={(input) => this.title = input} aria-describedby="name-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="text" placeholder="My Group" />
              </div>
              <div className="pv3">
                <label htmlFor="filesInput" className="f6 b db mb2">Upload Reviewees - CSV File</label>
                <input className="csv-input w-100" type="file" ref={input => { this.filesInput = input }} name="file" accept=".csv" placeholder={null} />
                <p><a href={ exampleUsersCSV }><strong>Click to download an example CSV file</strong></a></p>
              </div>
              <button className="b ph3 pv2 input-reset ba b--black bg-transparent pointer f6 dib" type="submit">Submit</button>
            </form>
          </div>
          }
        />







      </div>
    )
  }
}

export default withAuthorisation(authCondition)(RunOptions);
