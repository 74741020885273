import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Routes
import pageRoutes from './constants/routes';
//
import NoMatch from './components/no-match'
import withAuthentication from './components/withauthentication'; // withAuthentication higher level component - abstract the auth logic away

class App extends Component {

  render() {
    return (
      <Router>
        <Switch>
          {
            pageRoutes.map(({path, component, exact}, key) =>
              <Route exact={ exact } path={ path } key={ key } component={ component } />
            )
          }
          <Route component={ NoMatch } />
        </Switch>
      </Router>
    );
  }
}



export default withAuthentication(App);
