// Pages
import Contact from '../pages/contact';
import LandingPage from '../pages/landing';
import SignUpPage from '../pages/sign-up';
import SignInPage from '../pages/sign-in';
import PasswordForgetPage from '../pages/password-forget';
import Dashboard from '../pages/dashboard';
import AdminPage from '../pages/admin';
import SelectAccount from '../pages/select-account';
import AccountPage from '../pages/account';
//
import Edit360 from '../pages/360/edit';
import User360Report from '../pages/360/userreport';
import UserGoalReport from '../pages/360/usergoalreport';
import Edit360Basic from '../pages/360/edit/basic';
import Edit360Standard from '../pages/360/edit/standard';
import Edit360Relationships from '../pages/360/edit/standard/relationships';
import Edit360Group from '../pages/360/group/edit';
import Edit360Questionnaire from '../pages/360/edit/questionnaire';
import Edit360QuestionnaireSection from '../pages/360/edit/questionnaire-section';
import Edit360Pro from '../pages/360/edit/pro';

const pageRoutes = [
{
  path: '/signup/',
  component: SignUpPage,
  exact: true,
},
{
  path: '/contact/',
  component: Contact,
  exact: true,
},
{
  path: '/dashboard/',
  component: SelectAccount,
  exact: true,
},
{
  path: '/',
  component: SignInPage,
  exact: true,
},
{
  path: '/admin/',
  component: AdminPage,
  exact: true,
},
{
  path: '/passwordreset/',
  component: PasswordForgetPage,
  exact: true,
},
{
  path: '/dashboard/:accountId/',
  component: Dashboard,
  exact: true,
},
{
  path: '/dashboard/myaccount/',
  component: AccountPage,
  exact: true,
},
{
  path: '/dashboard/:accountId/360s/:id/edit/',
  component: Edit360,
  exact: true,
},
{
  path: '/dashboard/:accountId/360s/:id/edit/basic/questionnaire/:questionnaireSectionId/',
  component: Edit360QuestionnaireSection,
  exact: false,
},
{
  path: '/dashboard/:accountId/360s/:id/edit/basic/questionnaire/',
  component: Edit360Questionnaire,
  exact: false,
},
{
  path: '/dashboard/:accountId/360s/:id/edit/basic/',
  component: Edit360Basic,
  exact: false,
},
{
  path: '/dashboard/:accountId/360s/:id/edit/standard/',
  component: Edit360Standard,
  exact: false,
},
{
  path: '/dashboard/:accountId/360s/:id/edit/relationships/',
  component: Edit360Relationships,
  exact: false,
},

{
  path: '/dashboard/:accountId/360s/:id/report/:groupId/:userId/',
  component: User360Report,
  exact: false,
},
{
  path: '/dashboard/:accountId/360s/:id/goal-report/:groupId/:userId/',
  component: UserGoalReport,
  exact: false,
},
{
  path: '/dashboard/:accountId/360s/:id/edit/group/:groupId/',
  component: Edit360Group,
  exact: false,
},
]


export default pageRoutes;
