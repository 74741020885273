import React, { Component } from 'react';
import { compose } from 'recompose'
import { db } from '../firebase'
import withAuthorisation from '../components/withauthorisation';
import withUser360s from '../components/withuser360s';
import { Link } from 'react-router-dom'
import List360 from '../components/list360'
import SiteHeader from '../components/site-header'
import SiteNav from '../components/site-nav'
import SiteFooterBrand from '../components/site-footer-brand'
import Modal from '../components/global/modal'
import Autocomplete from 'react-autocomplete'
import { lightBoxStyles } from '../constants/lightbox.js'
const authCondition = (authUser) => !!authUser
//

class Contact extends Component {
  render() {
    return (
      <main>
        <SiteHeader />
        <SiteNav />
        <section className="mw8-l center pa4 ph4-l">
          <h1>Contact</h1>
        </section>
      </main>
    );
  }
}

export default compose(
  withAuthorisation(authCondition),
  withUser360s()
)(Contact)
