import React from 'react';
import { Link } from 'react-router-dom'
import SignOutButton from './signoutbutton';
import AuthUserContext from './authusercontext';


//

const Navigation = () =>
  <AuthUserContext.Consumer>
    { authUser => authUser
      ? <NavigationAuth />
      : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>

const NavigationAuth = () =>
  <nav className='mw9-ns ph4-ns center h-100 flex justify-between items-center'>
    <Link className="pv2 white no-underline" to="/dashboard/">Dashboard</Link>
    <Link className="pv2 white no-underline" to="/contact/">Contact Us</Link>
    <Link className="pv2 white no-underline" to="/account/">Settings</Link>
    <SignOutButton />
  </nav>

const NavigationNonAuth = () =>
  <nav className='mw9-ns ph4-ns center h-100 flex justify-between items-center'>
    <Link className="pv2 white no-underline" to="/">About</Link>
    <Link className="pv2 white no-underline" to="/signin/">Sign In</Link>
    <Link className="pv2 white no-underline" to="/signup/">Register</Link>
  </nav>

export default Navigation;
