import React from "react";
// import ReactExport from "react-export-excel";
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let dataSet = []
let data = []
let excelData = []

class ExportGroupButton extends React.Component {
  constructor(props) {
    super(props)

    let dataObj = this.props.reportdata.users
    console.log(dataObj)



    // MUSH THROUGH THE REPORT DATA OBJECT

    Object.keys(dataObj).map(function(reviewerKey) {
      data.push( [
        `${dataObj[reviewerKey].forename} ${dataObj[reviewerKey].surname}`,
        dataObj[reviewerKey].email,
        "",
        "Self",
        (dataObj[reviewerKey].completed) ? 'Completed' : 'Incomplete',
      ] )

      // Loop through each users Reviewer List
      Object.keys(dataObj[reviewerKey].reviewers).map(function(reviewersKey) {
        data.push( [
          `${dataObj[reviewerKey].forename} ${dataObj[reviewerKey].surname}`,
          dataObj[reviewerKey].email,
          (dataObj[reviewerKey].reviewers[reviewersKey].name) ? dataObj[reviewerKey].reviewers[reviewersKey].name : 'N/A',
          dataObj[reviewerKey].reviewers[reviewersKey].relationship,
          (dataObj[reviewerKey].reviewers[reviewersKey].completed) ? 'Completed' : 'Incomplete',
        ] )
      })

    });

    excelData = [
      {
        columns: [
          {title: "Reviewee", width: {wpx: 200}},
          {title: "Email", width: {wpx: 200}},
          {title: "Reviewer Name", width: {wpx: 200}},
          {title: "Relationship", width: {wpx: 200}},
          {title: "Review Status", width: {wpx: 200}},
        ],
        data: data
      }
    ]

    console.log(excelData);


  }
  render() {
    return (
      <ExcelFile filename="boomerang-export-group" element={<button className="btn">Download Data</button>}>
        <ExcelSheet dataSet={excelData} name={ this.props.reportdata.title }>
        {
          /*
          <ExcelColumn label="Reviewee" value="name"/>
          <ExcelColumn label="Email" value="email"/>
          <ExcelColumn label="Relationship" value="relationship"/>
          <ExcelColumn label="Reviewer Name" value="reviewername"/>
          <ExcelColumn label="Reviewer Email" value="revieweremail"/>
          <ExcelColumn label="Review Status" value="selfreviewstatus"/>

          */
        }
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

export default ExportGroupButton
