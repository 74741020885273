import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../../firebase'
import Breadcrumbs from '../../../components/breadcrumbs'
import SiteFooterBrand from '../../../components/site-footer-brand'
import SiteHeader from '../../../components/site-header'
import SiteNav from '../../../components/site-nav'

//
import { compose } from 'recompose'
import withAuthorisation from '../../../components/withauthorisation'
import withUser360s from '../../../components/withuser360s'
const authCondition = (authUser) => !!authUser
//

class Edit360Basic extends Component {
  constructor(props) {
    super(props)

    let editLink
    let editLinkQuestionnaire
    let editLinkBrand
    console.log(this.props.current360)
    this.state = {
      current360: this.props.current360,
      userDetails: this.props.userDetails,
      accountTitle: this.props.accountTitle,
      current360ID: null
    }
  }
  componentDidMount() {
    const { match: { params } } = this.props
    window.scrollTo(0,0)

    // this.editLinkBasic = `/dashboard/360s/${params.id}/edit/basic/`
    this.adminLink = `/dashboard/`
    this.editLink = `/dashboard/360s/${params.id}/edit/`
    this.editLinkQuestionnaire = `/dashboard/360s/${params.id}/edit/questionnaire/`
    this.editLinkBrand = `/dashboard/360s/${params.id}/edit/brand/`

  }
  render() {


    return (
      <main>
        <SiteHeader />
        <SiteNav />
        <section className="mw8-l center pa4 ph4-l">
          <Breadcrumbs
            current360={this.state.current360}
            userDetails={this.state.userDetails}
            accountTitle={this.state.accountTitle}
            accountData={this.props.accountData}
            links={[ {
              title: "Dashboard",
              to: "/dashboard/"
            },{
              title: this.state.accountTitle,
              to: "../../../"
            }, {
              title: this.state.current360.title,
              to: "../"
            }, {
              title: "Core Settings",
              to: null
            } ]} />

          <h1>{ this.state.current360.title }: Core Settings</h1>

          <div className="flex items-center justify-between w100 bg-white shadow-2 pa4 mv4">
            <div className="w-70">
              <p className="ma0"><strong>Questionnaire set-up</strong></p>
            </div>
            <Link className="btn" to={{ pathname: "questionnaire/", current360: this.state.current360, userDetails: this.state.userDetails, accountTitle: this.state.accountTitle }}>Start</Link>
          </div>


          <div className="flex items-center justify-between w100 bg-white shadow-2 pa4 mv4">
            <div className="w-70">
              <p className="ma0"><strong>Brand your 360</strong><br/>You may brand your 360 by setting a logo</p>
            </div>
            <Link className="btn" to={{ pathname: "brand/", current360: this.state.current360, userDetails: this.state.userDetails }}>Start</Link>
          </div>

          <Link to={{ pathname: "../", current360: this.state.current360, userDetails: this.state.userDetails, accountTitle: this.state.accountTitle }} className="f6 link ph4 pv3 dib white bg-green br2">Return to Project Overview</Link>



        </section>
        <SiteFooterBrand />
      </main>
    );
  }
}

export default compose(
  withAuthorisation(authCondition),
  withUser360s()
)(Edit360Basic)
