import React, { Component } from 'react'
import { db } from '../firebase'
import { Link } from 'react-router-dom'
import Dropdown from './global/dropdown'

let userActions = [
  {
    id: 0,
    title: 'Move',
    selected: false,
    key: 'userAction'
  },
  {
    id: 1,
    title: 'Notify',
    selected: false,
    key: 'userAction'
  },
  {
    id: 2,
    title: 'Generate Report',
    selected: false,
    key: 'userAction'
  }
]

let reviewersByType = {}

class RevieweeDetails extends Component {
  constructor(props) {
    super(props)
    console.log(this.props.groupUserDetails);
    // Empty reviewersByType object
    reviewersByType = {
      "DR": {

      },
      "P": {

      },
      "O": {

      },
      "LM": {

      }
    }

    this.state = {
      current360Id: this.props.current360Id,
      currentGroupId: this.props.currentGroupId,
      sectionAnswers: null,
      loading: true,
    }
    this.checkReportActive = this.checkReportActive.bind(this)
    this.checkGoalTrackerReportActive = this.checkGoalTrackerReportActive.bind(this)
    this.getNumCompleted = this.getNumCompleted.bind(this)
    this.fireUserAction = this.fireUserAction.bind(this)
    this.moveUser = this.moveUser.bind(this)
    this.notifyUser = this.notifyUser.bind(this)
    this.generateReport = this.generateReport.bind(this)
  }
  componentDidMount() {
    db.doGetAnswers(this.props.accountId, this.props.index, this.props.current360Id, this.props.currentGroupId, this.props.questionnaireId, "self-review").on('value', data => {
      console.log(data.val());
      this.setState({
        sectionAnswers: data.val(),
        loading: false,
        userReportLink: `/dashboard/360s/${this.state.current360Id}/${ (this.props.type == "360") ? 'report' : 'goal-report' }/${this.props.currentGroupId}/${this.props.index}/`
      })
    })
  }

  moveUser() {
    alert("move user")
  }

  notifyUser() {
    alert("notify user")
  }

  generateReport() {
    if(this.checkGoalTrackerReportActive()) {
      this.props.history.push(this.state.userReportLink);
    } else {
      alert("Cannot generate Report")
    }
  }

  checkReportActive() {
    console.log(this.props.groupUserDetails)
    if(!this.props.groupUserDetails.completed) {
      return false
    } else {
      if(this.getNumCompleted("DR") > 0 ||
        this.getNumCompleted("P") > 0 ||
        this.getNumCompleted("O") > 0 ||
        this.getNumCompleted("LM") > 0) {
          return true
      } else {
        return false
      }
    }
  }

  checkGoalTrackerReportActive() {
    // console.log();
    // console.log(this.state.sectionAnswers['sections']);
    // console.log(this.props.projectStatusCode);
    if(this.props.projectStatusCode == 1 || !this.state.sectionAnswers['sections']) {
      return false
    } else {
      return true
    }
  }

  getNumCompleted(type) {
    let numCompleted = 0
    Object
      .keys(reviewersByType[type])
      .map((key) => {
        if(reviewersByType[type][key].completed) {
          numCompleted++
        }
      })
    return numCompleted
  }

  fireUserAction(id, key) {
    let temp = JSON.parse(JSON.stringify(userActions));
    temp.forEach(item => item.selected = false);
    temp[id].selected = true;

    switch(id) {
      case 0:
        this.moveUser()
        break
      case 1:
        this.notifyUser()
        break
      case 2:
        this.generateReport()
        break
      default:
        return;
    }

  }

  render() {


    {
      if(this.props.groupUserDetails.reviewers) {
        Object
        .keys(this.props.groupUserDetails.reviewers)
        .map((key) => {

          if(this.props.groupUserDetails.reviewers[key].relationship) {

            reviewersByType[ this.props.groupUserDetails.reviewers[key].relationship ][key] = {
              'completed': this.props.groupUserDetails.reviewers[key].completed
            }

          }
        })
      }
    }


    return (
      <div className="flex bg-white mv2 items-center">

        {
          (this.props.type == "360") ?
            <div className="w-30 tc">
              <p><strong>{ this.props.groupUserDetails.forename } { this.props.groupUserDetails.surname }</strong><br/>Self-review: {
                (this.props.groupUserDetails.completed) ? (
                  <strong className="green">Complete</strong>
                ) : (
                  <strong className="red">Incomplete</strong>
                )
              }</p>
            </div>
          :
            <div className="w-30 tc">
              <p><strong>{ this.props.groupUserDetails.forename } { this.props.groupUserDetails.surname }</strong></p>
            </div>
        }


        {
          (this.props.type == "360") ?
            <div className="w-30 tc">
              <p className="flex">
                <strong className="w-25 br b--moon-gray">{Object.keys(reviewersByType["DR"]).length}/{ this.getNumCompleted("DR") }</strong>
                <strong className="w-25 br b--moon-gray">{Object.keys(reviewersByType["P"]).length}/{ this.getNumCompleted("P") }</strong>
                <strong className="w-25 br b--moon-gray">{Object.keys(reviewersByType["O"]).length}/{ this.getNumCompleted("O") }</strong>
                <strong className="w-25">{Object.keys(reviewersByType["LM"]).length}/{ this.getNumCompleted("LM") }</strong>
              </p>
            </div>
          :
          <div className="w-30 tc">
            <p className="flex">
            {
              (!this.state.loading) ?
              Object
                .keys(this.props.questionnaireSections)
                .map((key, i) => {
                  return (
                    <strong key={key} className={(i == 0) ? `w-100 br b--moon-gray bl` : 'w-100 br b--moon-gray'}><i className={ (this.state.sectionAnswers && this.state.sectionAnswers.sections[key] && this.state.sectionAnswers.sections[key]['goalComplete']) ? 'goal-status goal-status--complete' : 'goal-status' }></i></strong>
                  )
                })
              : <strong className="w-100">Loading status</strong>
            }
            </p>
          </div>
        }

        {
          (this.props.type == "360") ?
            <div className="w-20 tc">
              <p> - </p>
            </div>
          : ''
        }

        {
          (!this.state.loading) ?

            (this.props.type == "360") ?
              <div className="w-30 tc">
                <Dropdown
                  title="Alternate actions"
                  list={userActions}
                  setTitleOnSelect={false}
                  resetThenSet={this.fireUserAction}
                />
                {
                  (this.checkReportActive()) ?
                    <Link target="_blank" className="f6 link ph3 pv2 dib white bg-blue br2 bn tc" to={this.state.userReportLink}>View Report</Link>
                  : <p><strong className="red">Reviews incomplete</strong></p>
                }
              </div>
            :
              <div className="w-40 ph3">
                <Dropdown
                  title="Alternate actions"
                  list={userActions}
                  setTitleOnSelect={false}
                  resetThenSet={this.fireUserAction}
                />
              </div>

          : ''
        }


      </div>
    )
  }
}

export default RevieweeDetails;
