import React from 'react';
import { withRouter } from 'react-router-dom'
import AuthUserContext from './authusercontext'
import User360sContext from './user360scontext'
import { Link } from 'react-router-dom'
import { db, firebase } from '../firebase'
import loadingGif from '../loading.gif'
import { Helmet } from "react-helmet-prepend"
// import {Helmet} from "react-helmet"

const withUser360s = (aaa) => (Component) => {
  class WithUser360s extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        currentAccountId: props.match.params.accountId,
        current360Id: props.match.params.id,
        current360: props.location.current360,
        userDetails: props.location.userDetails,
        accountTitle: props.location.accountTitle,
        accountBranding: props.location.accountBranding,
        accountData: props.location.accountData,
        error: false,
        errorText: "",
        loading: true
      };

      this.loadAccountOnly = this.loadAccountOnly.bind(this);
      this.loadAccountAndSelected360 = this.loadAccountAndSelected360.bind(this);
      this.load360 = this.load360.bind(this);
    }

    componentDidMount() {
      if(!this.props.match.params.id) {
        this.loadAccountOnly()
      } else {
        this.loadAccountAndSelected360()
      }
    }

    loadAccountOnly() {
      console.log('loadAccountOnly')
      if(this.state.userDetails) {
        console.log("user details loaded previously")
        this.setState({
          loading: false
        })
        // alert("no need to load 360 again!")
      } else {
        db.doGetLoggedInUserDetails(firebase.auth.currentUser.uid).then((data) => {
          let userDetails = data.val()
          this.setState({
            userDetails: data.val()
          })

          console.log("checking if account ID correct")
          // console.log(userDetails.accounts)
          for (let acc of userDetails.accounts) {
            if(acc.id === this.state.currentAccountId) {
              console.log('correct account found')
              // Get Account Branding
              db.doGetAccountBranding(acc.id).then((data) => {
                console.log(data.val());
                this.setState({
                  accountBranding: data.val(),
                  accountTitle: acc.title,
                  loading: false
                })
              })

            } else {
              // console.log('ERRORR!');
              this.setState({
                error: true,
                loading: false
              })
            }
          }
        })
      }


    }

    loadAccountAndSelected360() {
      console.log('loadAccountAndSelected360');

      if(this.state.userDetails) {
        console.log("user details loaded previously, just get current360");
        this.load360()

        // alert("no need to load 360 again!")
      } else {
        console.log("we need to load user details")
        db.doGetLoggedInUserDetails(firebase.auth.currentUser.uid).then((data) => {
          console.log(data.val());
          let userDetails = data.val()
          this.setState({
            userDetails: data.val()
          })
          console.log("checking if account ID correct")
          // console.log(userDetails.accounts)
          for (let acc of userDetails.accounts) {
            console.log(acc)
            if(acc.id === this.state.currentAccountId) {
              console.log('correct account found')
              db.doGetAccountBranding(acc.id).then((data) => {
                console.log(data.val());
                this.setState({
                  accountBranding: data.val(),
                  accountTitle: acc.title
                })
                this.load360()
              })

            } else {
              // console.log('ERRORR!');
              this.setState({
                error: true,
                loading: false
              })
            }
          }
          // accountId


        })

        // alert("we need to load 360 details")
      }
    }

    load360() {
      // console.log('load360')
      if(!this.state.current360) {
        console.log("load360")
        db.doGetCurrent360(this.state.currentAccountId, this.state.current360Id).on('value', (data) => {
          console.log(data.val());
          this.setState({
            current360: data.val(),
            loading: false
          })
        })

      } else {
        console.log("360 in state")
        db.doGetAccountBranding(this.state.currentAccountId).then((data) => {
          this.setState({
            accountBranding: data.val(),
            loading: false
          })
        })

      }
    }

    render() {
      const { userDetails, current360, accountTitle, accountBranding } = this.state;
      const { loading, error } = this.state;
      const accountData = {...userDetails, ...current360, accountTitle, ...accountBranding};
      // console.log("accountData");
      // console.log(accountData);
      if(loading) {
        return (
          <div className="vh-100 vw-100 flex items-center justify-center">
            <img src={ loadingGif } />
          </div>
        )
      }
      if(error) {
        return (
          <div className="vh-100 vw-100 flex items-center justify-center">
            <div>
              <p>There was an error loading this page.</p>
              <p><Link className="btn" to="/dashboard/">Return to dashboard</Link></p>
            </div>
          </div>
        )
      }
      return (
        <User360sContext.Provider>
          <Helmet>
            <style>{`
              :root {
                  --primarycolour: ${accountData.primarycolour};
                  --secondarycolour: ${accountData.secondarycolour};
                  --tertiarycolour: ${accountData.tertiarycolour};
                  --logoimg: url(${accountData.logo});
              }
              `}
            </style>
          </Helmet>
          <Component accountData={ accountData } userDetails={ userDetails } current360={ current360 } accountTitle={ accountTitle } accountBranding={ accountBranding } {...this.props} />
        </User360sContext.Provider>
      );

      // return (
      //   <User360sContext.Provider>
      //     <Component foo="bar" {...this.props} />
      //   </User360sContext.Provider>
      // );
    }
  }

  return WithUser360s;

}

export default withUser360s;
