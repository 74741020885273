import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import { db } from '../../firebase'
import { PDFExport } from '@progress/kendo-react-pdf'
import canvg from 'canvg'
import { Link } from 'react-router-dom'
import { average, relationshipColor, convertRelationships, difference } from '../../helpers'
import RadarChart from '../../components/charts/radarchart'
import SiteHeader from '../../components/site-header'
import LoadingState from '../../components/global/loading-state'
import moment from 'moment'
import withAuthorisation from '../../components/withauthorisation'
const authCondition = (authUser) => !!authUser
//


// const captions = {}

class UserGoalReport extends Component {
  constructor() {
    super()
    this.exportPDF = this.exportPDF.bind(this)
    this.convertSVGToImage = this.convertSVGToImage.bind(this)
    this.canvLoaded = false
    this.hiddenCanvas = React.createRef();

    this.state = {
      loading: true,
      captions: {},
      userData: {},
      userId: null,
      groupId: null,
      current360Id: null,
    }
  }
  exportPDF = () => {
    this.report.save();
  }
  convertSVGToImage = (htmlString) => {
    // Take an SVG html string, convert it to static markup, then use canvg to render into hidden canvas, then return dataURL string
    let staticRadarChart = ReactDOMServer.renderToStaticMarkup(htmlString);
    let canv = this.hiddenCanvas.current
    canv.getContext("2d")

    canvg(canv, staticRadarChart);
    return canv.toDataURL("image/png")
  }
  componentDidMount() {
    const { match: { params } } = this.props
    this.setState({
      userId: params.userId,
      groupId: params.groupId,
      current360Id: params.id,
    });

    db.getUserFromGroup(params.userId).then((data) => {
      let userFullName = `${data.val()['forename']} ${data.val()['surname']}`
      let reportPdfFileName = `${data.val()['forename']}-${data.val()['surname']}-goal-tracker-report.pdf`

      db.doGetCurrent360(params.id).then((data) => {
        let questionnaireID = data.val()['questionnaireID']
        let current360Title = data.val()['title']

        db.doGetSectionsList(questionnaireID).on('value', data => {

          let questionnaireSections = data.val().sections
          let questionDetails = {}
          let sectionDetails = questionnaireSections
          let userAnswers = {}
          let userResponses = {}
          let userComments = {}
          let userIndividualAnswers = []
          let userIndividualResponses = []

            // Get answers!
            db.doGetAnswers(params.userId, params.id, params.groupId, questionnaireID, "self-review").on('value', data => {

              let userAnswers = data.val()

              this.setState({
                  userAnswers: userAnswers,
                  questionnaireSections: questionnaireSections,
                  questionDetails: questionDetails,
                  current360Title: current360Title,
                  sectionDetails: sectionDetails,
                  userFullName: userFullName,
                  reportPdfFileName: reportPdfFileName,
                  loading: false,
                })


              })


            })

        })
    }).catch((data) => {
      console.log('error');
    })


  }
  render() {
    if(!this.state.loading) {


      return (
        <div style={{ height: '100vh', width: '100vw', paddingTop: 20, backgroundColor: 'gray', overflow: 'hidden' }}>


          <p className="tc">
            <button onClick={this.exportPDF}>download</button>
          </p>
          <PDFExport paperSize={'A4'}
            forcePageBreak=".page-break"
            fileName={ this.state.reportPdfFileName }
            title=""
            subject=""
            keywords=""
            ref={(r) => this.report = r}>
            <div style={{
                height: '90vh',
                maxWidth: 900,
                width: '100%',
                padding: 'none',
                backgroundColor: 'white',
                boxShadow: '5px 5px 5px #666',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 100,
                overflowX: 'scroll',
                overflowY: 'scroll'}}>
            <header className="bg-blue pa5">
              <div className="pb7">
                <h2 className="white">Boomerang - Goal Tracker</h2>
              </div>
              <h1 className="white f1 mv0">{this.state.current360Title}</h1>
              <p className="white"><strong>Report and Development plan for { this.state.userFullName }</strong></p>
            </header>
            <div className="bg-white pa5">
              <h1 className="f2 mv0 light-blue">Section 1:</h1>
              <h2 className="f1 mv0">Instructions</h2>

              <div className="mv4">
                <h1 className="f2 mv0 light-blue">Section 1.1:</h1>
                <h3 className="f5 gray">Getting the most from your goal tracker</h3>
                <p><strong>Why set goals?</strong></p>
                <p>Goals are an essential tool and are key to ensuring your progress and personal fulfilment.</p>
                <p><strong>Plan to achieve your goals</strong></p>
                <p>It is important to consider how you are going to monitor progress and ensure that anything that might impede progress can be easily identified and dealt with. Good communication is key to this and regular meetings or review sessions can help to pre-empt difficulties.</p>
                <p>You may also have specific processes or procedures in place that help monitor large-scale tasks. If your goals are personal, make sure you review them regularly to check that you are still on track, if not then do something about it – don’t set yourself up for failure.</p>
                <p><strong>Feel the benefit</strong></p>
                <p>You should feel motivated by the challenge your goals represent. Use them to demonstrate your abilities and achievements; allowing them to validate your successes and effectiveness.</p>
                <p>Goal setting can be used to great effect at any level and for any purpose, both in your work and personal life.</p>
              </div>

              <div className="mv4">
                <h1 className="f2 mv0 light-blue">Section 1.2:</h1>
                <h3 className="f5 gray">How your report is compiled</h3>
                <p><strong>Goals</strong></p>
                <p>The goals you set up on the goal tracker system.</p>
                <p><strong>Specific actions</strong></p>
                <p>The actions you identified that would enable you to deliver your goals.</p>
                <p><strong>Measures of success</strong></p>
                <p>The measures you chose to identify how you will know you have been successful in delivering your goal.</p>
                <p><strong>Support needed</strong></p>
                <p>Any people, resources, etc that you required to deliver your goals.</p>
                <p><strong>Review point</strong></p>
                <p>The date you will review your progress on your goals.</p>
                <p><strong>Deadline</strong></p>
                <p>The deadline set for the completion of your goals.</p>
              </div>

              <h1 className="f2 mv0 light-blue page-break">Section 2:</h1>
              <h2 className="f1 mv0">My Goals</h2>

              {

                Object
                .keys(this.state.questionnaireSections)
                .map((key, i) => {
                  let sectionKey = key
                  if(this.state.userAnswers.sections[sectionKey]) {
                    return (
                      <div className="mv5 page-break">
                        <h1 className="f2 mv0 light-blue">Section 2.{ i+1 }:</h1>
                        <h3 className="f3 blue">{ this.state.questionnaireSections[sectionKey].title }<br/>
                        <span className="f5 gray">{ this.state.questionnaireSections[sectionKey].description }</span></h3>

                        {
                          Object
                          .keys(this.state.questionnaireSections[sectionKey].questions)
                          .map((key, i) => {
                            let questionKey = key
                            return (
                              <div className="mv4">
                                <span className="f5 gray">{ this.state.questionnaireSections[sectionKey].questions[questionKey].questionTitle }</span>
                                {
                                  (this.state.questionnaireSections[sectionKey].questions[questionKey].answerType == "Date") ?
                                    <div class="bg-light-blue pv3 ph4 mt1"><p class="black">{ moment(this.state.userAnswers.sections[sectionKey].answers[questionKey]).format('MMMM Do YYYY') }</p></div>

                                  :
                                  <div class="bg-light-blue pv3 ph4 mt1"><p class="black">"{ this.state.userAnswers.sections[sectionKey].answers[questionKey] }"</p></div>

                                }
                              </div>
                            )
                          })
                        }

                      </div>
                    )
                  }


                })
              }

              <h1 className="f2 mv0 light-blue page-break">Section 3:</h1>
              <h2 className="f1 mv0">Reflections</h2>

              <h1 className="f2 mv0 light-blue">Section 3.1:</h1>
              <h3 className="f5 gray">My Reflections</h3>

              <p><strong>Use the space below to reflect on your goals, as required:</strong></p>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>


            </div>
          </div>
          </PDFExport>
        </div>
      )
    } else {
      return (
        <div>
          <canvas ref={this.hiddenCanvas}></canvas>
          <LoadingState />
        </div>
      )
    }

  }
}

//
// export default withAuthorisation(authCondition)(UserGoalReport);
export default UserGoalReport
