import React, { Component } from 'react';
import SiteHeader from '../components/site-header'

//

class PasswordForgetPage extends Component {
  render() {
    return (
      <div className="pt6 pb6">
        <SiteHeader />
        <h1>Reset your password</h1>
      </div>
    );
  }
}

//

export default PasswordForgetPage;
