import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import { db } from '../../firebase'
import { PDFExport } from '@progress/kendo-react-pdf'
import canvg from 'canvg'
import { Link } from 'react-router-dom'
import { average, relationshipColor, convertRelationships, difference } from '../../helpers'
import RadarChart from '../../components/charts/radarchart'
import SiteHeader from '../../components/site-header'
import LoadingState from '../../components/global/loading-state'
import withAuthorisation from '../../components/withauthorisation'
const authCondition = (authUser) => !!authUser
//


// const captions = {}

class User360Report extends Component {
  constructor() {
    super()
    this.exportPDF = this.exportPDF.bind(this)
    this.convertSVGToImage = this.convertSVGToImage.bind(this)
    this.canvLoaded = false
    this.hiddenCanvas = React.createRef();

    this.state = {
      loading: true,
      captions: {},
      userData: {},
      userId: null,
      groupId: null,
      current360Id: null,
    }
  }
  exportPDF = () => {
    this.report.save();
  }
  convertSVGToImage = (htmlString) => {
    // Take an SVG html string, convert it to static markup, then use canvg to render into hidden canvas, then return dataURL string
    let staticRadarChart = ReactDOMServer.renderToStaticMarkup(htmlString);
    let canv = this.hiddenCanvas.current
    canv.getContext("2d")

    canvg(canv, staticRadarChart);
    return canv.toDataURL("image/png")
  }
  componentDidMount() {
    const { match: { params } } = this.props
    this.setState({
      userId: params.userId,
      groupId: params.groupId,
      current360Id: params.id,
    });

    db.getUserFromGroup(params.userId).then((data) => {
      let userFullName = `${data.val()['forename']} ${data.val()['surname']}`

      db.doGetCurrent360(params.id).then((data) => {
        let questionnaireID = data.val()['questionnaireID']
        let current360Title = data.val()['title']

        db.doGetSectionsList(questionnaireID).on('value', data => {

          let questionnaireSections = data.val().sections
          let captions = {}
          let descriptions = {}
          let questionDetails = {}
          let sectionDetails = questionnaireSections
          let userAnswers = {}
          let userResponses = {}
          let userComments = {}
          let userIndividualAnswers = []
          let userIndividualResponses = []
          // let reviewerAnswers = {}

          Object
            .keys(questionnaireSections)
            .map((key,index) => {
              let sectionID = key
              // Check if only one question AND if that question is a FreeText field!!
              if(Object.keys(questionnaireSections[sectionID].questions).length == 1) {
                // MAD:
                if( questionnaireSections[sectionID].questions[Object.keys(questionnaireSections[sectionID].questions)[0]].answerType === "FreeText" ) {
                  return
                }
              }
              // Set captions + description!
              captions[[sectionID]] = questionnaireSections[sectionID].title
              descriptions[[sectionID]] = questionnaireSections[sectionID].description


              Object
                .keys(questionnaireSections[sectionID].questions)
                .map((key,index) => {
                  // console.log(questionnaireSections[sectionID].questions[key])
                  questionDetails[[key]] = questionnaireSections[sectionID].questions[key]
                })

            })

            console.log(questionDetails);
            // Get answers!
            db.doGetAnswers(params.userId, params.id, params.groupId, questionnaireID, "self-review").on('value', data => {
              let questionnaireSections = data.val().sections

              // console.log(questionnaireSections)
              Object
                .keys(questionnaireSections)
                .map((key,index) => {
                  let sectionKey = key
                  let answers = []
                  let comments = []
                  let responses = [0,0,0,0,0,0]
                  Object
                    .keys(questionnaireSections[key].answers)
                    .map((key,index) => {
                      let answerVal = questionnaireSections[sectionKey].answers[key]
                      // If answer is a number value (IE: not a text field) then set, otherwise set to `0`
                      answerVal = isNaN(parseInt(answerVal)) ? -1 : parseInt(answerVal)
                      if(answerVal == -1) {
                        comments[index] = questionnaireSections[sectionKey].answers[key]
                      }

                      responses[answerVal] = responses[answerVal] + 1

                      answers[index] = answerVal

                      userIndividualAnswers[[key]] = answerVal
                      // console.log(responses)
                      // userIndividualResponses[[key]] = responses
                    })

                    // console.log(comments);
                    //
                    // if( !userIndividualAnswers[[key]] ) {
                    //   selfReviewIndividualQuestions[[key]] = []
                    // }

                  // console.log(selfReviewIndividualQuestions)
                  userAnswers[[sectionKey]] = ( average(answers) / 5 )
                  userResponses[[sectionKey]] = responses
                  // console.log(userResponses)
                  // userAnswers[[sectionKey]]['averages'] = ( average(answers) / 5 )
                  // userAnswers[[sectionKey]]['responses'] = ( average(answers) / 5 )
                  userComments[[sectionKey]] = ( comments )
                  //

                })




              // Get Reviewer Answers.
              db.doGetCompletedReviewers(params.id, params.groupId, params.userId).then((data) => {

                if(data.val()) {

                  let reviewers = data.val()
                  let numberOfReviewers = Object.keys(reviewers).length
                  let completedReviewerAnswersAverage = {}
                  let completedReviewerComments = {}
                  let completedReviewerIndividualQuestionsAverage = {}
                  let completedReviewerAnswers = {
                    "DR": {},
                    "LM": {},
                    "P": {},
                    "O": {},
                  }
                  let completedReviewerAnswersByRelationship = {}
                  let completedIndividualQuestionsDifferences = {}

                  let completedReviewerResponsesByRelationship = {}

                  let completedReviewerResponses = {
                    "DR": {},
                    "LM": {},
                    "P": {},
                    "O": {},
                  }


                  // Loop through all completed reviewers + split into correct relationship Groups
                  // and then get average of section scores
                  Object
                    .keys(reviewers)
                    .map((key,index) => {
                      let loopNum = index+1
                      let reviewerRelationship = reviewers[key]["relationship"]

                      db.doGetReviewerAnswers(key).then((data) => {
                        let reviewerAnswersSections = data.val().sections
                        let answers = []

                        Object
                          .keys(reviewerAnswersSections)
                          .map((key,index) => {
                            let sectionKey = key
                            let answers = []
                            let comments = []
                            let responses = [0,0,0,0,0,0]

                            // console.log(reviewerAnswersSections[key]);

                            // Loop through answers in each Section
                            Object
                              .keys(reviewerAnswersSections[key].answers)
                              .map((key,index) => {
                                let count = index
                                count++

                                let answerVal = reviewerAnswersSections[sectionKey].answers[key]
                                // If answer is a number value (IE: not a text field) then set, otherwise set to `0`
                                answerVal = isNaN(parseInt(answerVal)) ? -1 : parseInt(answerVal)
                                answers[index] = answerVal

                                if(answerVal == -1) {
                                  comments[index] = reviewerAnswersSections[sectionKey].answers[key]
                                } else {
                                  responses[answerVal] = responses[answerVal] + 1

                                  //
                                  if( !completedReviewerIndividualQuestionsAverage[[key]] ) {
                                    completedReviewerIndividualQuestionsAverage[[key]] = []
                                  }
                                  completedReviewerIndividualQuestionsAverage[[key]].push( answerVal )




                                  if( !completedReviewerAnswersByRelationship[[key]] ) {
                                    completedReviewerAnswersByRelationship[[key]] = []
                                  }

                                  if( !completedReviewerAnswersByRelationship[[key]][[reviewerRelationship]] ) {
                                    completedReviewerAnswersByRelationship[[key]][[reviewerRelationship]] = []
                                  }

                                  completedReviewerAnswersByRelationship[[key]][[reviewerRelationship]].push( answerVal )



                                  if( !completedReviewerResponsesByRelationship[[key]] ) {
                                    completedReviewerResponsesByRelationship[[key]] = []
                                  }

                                  if( !completedReviewerResponsesByRelationship[[key]][[reviewerRelationship]] ) {
                                    completedReviewerResponsesByRelationship[[key]][[reviewerRelationship]] = []
                                  }

                                  completedReviewerResponsesByRelationship[[key]][[reviewerRelationship]].push( responses )
                                }


                                // if(count == Object.keys( reviewerAnswersSections[sectionKey].answers ).length) {
                                //
                                // }



                              })

                              // console.log(answers)
                            completedReviewerAnswers[reviewerRelationship][[sectionKey]] = ( average(answers) / 5 )


                            completedReviewerResponses[reviewerRelationship][[sectionKey]] = responses

                            if( !completedReviewerAnswersAverage[[sectionKey]] ) {
                              completedReviewerAnswersAverage[[sectionKey]] = []
                            }
                            completedReviewerAnswersAverage[[sectionKey]].push( average(answers) )

                            // Get all user Comments per-section (anonymously)
                            if( !completedReviewerComments[[sectionKey]] ) {
                              completedReviewerComments[[sectionKey]] = []
                            }
                            completedReviewerComments[[sectionKey]][[loopNum]] = comments[0]

                          })


                          if(loopNum == numberOfReviewers) {
                            // console.log('got all reviewer scores!')

                            // console.log(completedReviewerIndividualQuestionsAverage)

                            Object
                              .keys(completedReviewerAnswersAverage)
                              .map((key,index) => {
                                let completedAverage = (average(completedReviewerAnswersAverage[key])).toFixed(1)
                                completedReviewerAnswersAverage[key] = completedAverage
                              })

                            Object
                              .keys(completedReviewerIndividualQuestionsAverage)
                              .map((key,index) => {
                                let completedAverage = (average(completedReviewerIndividualQuestionsAverage[key])).toFixed(1)
                                completedReviewerIndividualQuestionsAverage[key] = completedAverage

                                let descrepency = difference(userIndividualAnswers[key], completedReviewerIndividualQuestionsAverage[key]).toFixed(1)
                                completedIndividualQuestionsDifferences[key] = descrepency
                              })


                              // console.log("completedReviewerIndividualQuestionsAverage!");
                              // console.log(completedReviewerIndividualQuestionsAverage)
                              // console.log("userIndividualAnswers");
                              // console.log(userIndividualAnswers);
                              // console.log("completedIndividualQuestionsDifferences");
                              // console.log(completedIndividualQuestionsDifferences);
                              // console.log("completedReviewerAnswersByRelationship");
                              // console.log(completedReviewerAnswersByRelationship);
                              console.log("completedReviewerResponsesByRelationship");
                              console.log(completedReviewerResponsesByRelationship);
                              console.log("completedReviewerAnswersByRelationship");
                              console.log(completedReviewerAnswersByRelationship);
                              this.setState({
                                userAnswers: userAnswers,
                                userResponses: userResponses,
                                userComments: userComments,
                                completedReviewerComments: completedReviewerComments,
                                userIndividualAnswers: userIndividualAnswers,
                                userIndividualResponses: userIndividualResponses,
                                reviewerAnswers: completedReviewerAnswers,
                                reviewerResponses: completedReviewerResponses,
                                reviewerAnswersAverage: completedReviewerAnswersAverage,
                                completedReviewerIndividualQuestionsAverage: completedReviewerIndividualQuestionsAverage,
                                completedReviewerAnswersByRelationship: completedReviewerAnswersByRelationship,
                                completedReviewerResponsesByRelationship: completedReviewerResponsesByRelationship,
                                completedIndividualQuestionsDifferences: completedIndividualQuestionsDifferences,
                                captions: captions,
                                descriptions: descriptions,
                                questionDetails: questionDetails,
                                current360Title: current360Title,
                                sectionDetails: sectionDetails,
                                userFullName: userFullName,
                                loading: false,
                              })

                          }


                      })

                    })
                } else {
                  this.setState({
                    userAnswers: userAnswers,
                    captions: captions,
                    descriptions: descriptions,
                    loading: false
                  })
                }


              })


            })



        })
      })
    }).catch((data) => {
      console.log('rttt');
    })


  }
  render() {
    if(!this.state.loading) {

      let emptyData = {
        data: {},
        meta: {},
      }

      const data = [
        {
          data: {

          },
          meta: { color: 'blue' }
        }
      ];

      data[0]['data'] = this.state.userAnswers

      if(this.state.reviewerAnswers) {
        Object
          .keys(this.state.reviewerAnswers)
          .map((key,index) => {
            if(Object.keys(this.state.reviewerAnswers[key]).length) {
              data.push({
                data: this.state.reviewerAnswers[key],
                meta: {
                  color: relationshipColor(key)
                }
              });
            }
          })
      }





      const chartOptions = {
        size: 200,
        axes: true, // show axes?
        scales: 5, // show scale circles?
        captions: true, // show captions?
        captionMargin: 10,
        dots: true, // show dots?
        zoomDistance: 1.3, // where on the axes are the captions?
        axisProps: () => ({ className: 'axis', fill: 'none', stroke: '#000' }),
        scaleProps: () => ({ className: 'scale', fill: 'none', stroke: '#000' }),
        shapeProps: () => ({ className: 'shape' }),
        captionProps: () => ({
          className: 'caption',
          textAnchor: 'middle',
          fontSize: 14,
          fontFamily: 'sans-serif'
        })
      };

      let reviewerAnswersAverage = this.state.reviewerAnswersAverage
      let completedReviewerIndividualQuestionsAverage = this.state.completedReviewerIndividualQuestionsAverage
      let completedIndividualQuestionsDifferences = this.state.completedIndividualQuestionsDifferences

      return (
        <div style={{ height: '100vh', width: '100vw', paddingTop: 20, backgroundColor: 'gray', overflow: 'hidden' }}>


          <p className="tc">
            <button onClick={this.exportPDF}>download</button>
          </p>
          <PDFExport paperSize={'A4'}
            forcePageBreak=".page-break"
            fileName="_____.pdf"
            title=""
            subject=""
            keywords=""
            ref={(r) => this.report = r}>
            <div style={{
                height: '90vh',
                maxWidth: 900,
                width: '100%',
                padding: 'none',
                backgroundColor: 'white',
                boxShadow: '5px 5px 5px #666',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 100,
                overflowX: 'scroll',
                overflowY: 'scroll'}}>
            <header className="bg-blue pa5">
              <div className="pb7">
                <h2 className="white">Boomerang</h2>
              </div>
              <h1 className="white f1 mv0">{this.state.current360Title}</h1>
              <p className="white"><strong>Report and Development plan for { this.state.userFullName }</strong></p>
            </header>
            <div className="bg-white pa5">
              <h1 className="f2 mv0 light-blue">Section 1:</h1>
              <h2 className="f1 mv0">Introduction</h2>
              <h3 className="f5 gray">How to get the best from your feedback</h3>
              <p><strong>Choose how you want to work through the report:</strong></p>
              <p>Your 360 feedback report has been designed to be read in the order that the sections appear, and it’s a good idea to work through the feedback in that order. However, you can if you prefer, jump straight into any section and then get related information from other parts of the report. To see how the different parts of the report relate to each other, please see 'How this report is structured'.</p>
              <p><strong>Look for consistent messages:</strong></p>
              <p>360 degree feedback is about finding and focusing on clear and consistent themes, so look for capabilities and statements where you and your colleagues, or different colleague groups, have given similar ratings or comments. These consistent themes will guide you on areas of strength, and areas you may want to develop and improve.</p>
              <p>One-off or isolated ratings or comments should be taken in context and interpreted carefully; if you do have these, be sure to balance them against other feedback in your report. However, they may also contain a kernel of truth, so it may be worth considering why they have been given.</p>
              <p><strong>Make notes as you go along:</strong></p>
              <p>There are places in this report where you can make notes, observations and reflections. Completing these notes will help you to summarise your thoughts as you go along, and will make it easier to build your development plan.</p>
              <p><strong>Build your development plan:</strong></p>
              <p>Feedback should help you to grow and develop; think about how you can put this feedback into action by developing your statements in practical and positive ways.</p>
              <p><strong>Ask for help if you need to:</strong></p>
              <p>If you find you are not sure about some of the feedback in this report, talk to a trusted colleague, manager or coach to help you to interpret the feedback and put it into context.</p>

              <h3 className="f5 gray">How this report is structured</h3>
              <p><strong>Capabilities and statements</strong></p>
              <ul>
                <li>Capabilities are groups of related statements that together describe a broader range of statements. Each capability is made up of a number of statements; these are actions or behaviours which describe that capability in more detail.</li>
                <li>In the online questionnaire, you and your colleagues rated each statement; these ratings have been aggregated in this report to provide an overall rating for the capability to which they belong.</li>
                <li>Each capability and its constituent statements has been allocated a specific colour in this report, so that you can identify key themes as you work through the report.</li>
              </ul>
              <p><strong>Ratings calculations</strong></p>
              <p>You and your colleagues have rated each statement against a rating scale when completing the online questionnaire. These are collated and averaged to show the rating for each statement, and for each of the overall capabilities.</p>
              <p>Your self-review ratings are shown separately from those of your colleagues. Self-review ratings are not usually included in the average ratings.</p>
              <p><strong>'Unable to Comment' rating</strong></p>
              <p>Where this rating has been given by you or your colleagues, it is not included in the rating calculation for that statement, or in the overall capability rating.</p>
              <p><br/><br/><br/></p>



              <h1 className="f2 mv0 light-blue page-break">Section 2:</h1>
              <h2 className="f1 mv0">Your feedback summary</h2>
              <h1 className="f2 mv0 light-blue">Section 2.1:</h1>
              <h2 className="f2 mv0">Capabilities: comparison by colleague group</h2>
              <p>This chart compares the ratings for each of your capabilities, by each group of colleagues who have given you feedback. It also compares each rating with your self-review.</p>
              <p>Capabilities with similar ratings mean consistency of message: consistently highly rated capabilities indicate strengths, whilst consistently lower rated capabilities can indicate general areas you may want to change and improve.</p>
              <p>Capabilities where there is a big gap between ratings suggest a mismatch between different colleagues' experiences in working with you, or different perceptions. These can be explored in more detail in 'Your detailed feedback'.</p>

              <p><span className="w1 h1 dib br-100 bg-blue v-mid mr2"></span> <strong>Self</strong></p>
              {
                (this.state.reviewerAnswers) ?
                  Object
                    .keys(this.state.reviewerAnswers)
                    .map((key,index) => {
                      if(Object.keys(this.state.reviewerAnswers[key]).length) {
                        return (<p key={key}><span className="w1 h1 dib br-100 v-mid mr2" style={{background: relationshipColor(key)}}></span> <strong>{ convertRelationships(key) }</strong></p>)
                      }
                    })
                : ''
              }


              <div className="radar">
              {
                (
                  <img src={this.convertSVGToImage(
                    <RadarChart
                      captions={this.state.captions}
                      data={data}
                      size={600}
                      options={chartOptions}
                    />
                  )} />
                )
              }
              </div>



              <div className="mv5">
                <h3 className="f5 gray i">Reflection</h3>
                <p>What initial impressions do you have from this graph about your possible strengths or areas for development?</p>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              </div>

              <h1 className="f2 mv0 light-blue">Section 2.2:</h1>
              <h2 className="f2 mv0">Capabilities: ranked highest to lowest</h2>
              <p>This chart lists your capabilities, ranked highest to lowest, based on your colleagues' ratings. You can also compare each rating against your self-review.This will give you an indication of your general strengths and development needs. These can be explored in more detail in 'Your detailed feedback'.</p>

              <div className="bb b--light-gray mv5">
              {
                /* Loop through reviewersAnswersAverage (sorting in numerical order!) */
                (reviewerAnswersAverage) ?
                Object
                  .keys(reviewerAnswersAverage)
                  .sort(function(a,b){return reviewerAnswersAverage[b] - reviewerAnswersAverage[a]})
                  .map((key,index) => {
                    if(reviewerAnswersAverage[key] > 0) {
                      return (
                        <div key={key} className="w-100 flex bt b--light-gray">
                          <div className="w-40">
                            <p>{this.state.captions[key]}</p>
                          </div>
                          <div className="w-20">
                            <p className="blue"><strong>Reviewers</strong></p>
                            <p className="blue"><strong>Self</strong></p>
                          </div>
                          <div className="w-40">
                            <div className="w-100"><p className="flex"><strong className="w-10">{reviewerAnswersAverage[key]}</strong>
                              <span className="w-100 bg-light-gray">
                                <span className="bg-blue h-100 db" style={{width: `${ (reviewerAnswersAverage[key] / 5) * 100 }%` }}></span>
                              </span>
                            </p></div>
                            <div className="w-100"><p className="flex"><strong className="w-10">{(this.state.userAnswers[key] * 5).toFixed(1)}</strong>
                              <span className="w-100 bg-light-gray">
                                <span className="bg-blue h-100 db" style={{width: `${ ((this.state.userAnswers[key] * 5).toFixed(0) / 5) * 100 }%` }}></span>
                              </span>
                            </p></div>
                          </div>
                        </div>
                      )
                    }

                  })
                : ''
              }
              </div>

              <div className="mv5">
                <h3 className="f5 gray i">Reflection</h3>
                <p>Based on this chart, what appear to be your strongest and least strong capability areas?</p>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <p>How does this align with your self perceptions?</p>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <p>Which capabilities may be the most relevant for you to look into further?</p>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              </div>







              <h1 className="f2 mv0 light-blue">Section 2.3:</h1>
              <h2 className="f2 mv0">Statements: ranked highest and lowest</h2>
              <p>This chart shows your highest ranked statements, and your lowest ranked statements, based on your colleagues' ratings. You can also compare each rating against your self-review for the same statement.</p>
              <p>This will show you the specific statements that have been observed by your colleagues as strongest and the least strong. These should generally align with the capability rankings and can be explored in more detail in 'Your detailed feedback'.</p>

              <div className="bb b--light-gray mv5">
              <p><strong>Highest Average Statements</strong></p>
              {
                /* Loop through completedReviewerIndividualQuestionsAverage (sorting in numerical order!) */
                (completedReviewerIndividualQuestionsAverage) ?
                Object
                  .keys(completedReviewerIndividualQuestionsAverage)
                  .sort(function(a,b){return completedReviewerIndividualQuestionsAverage[b] - completedReviewerIndividualQuestionsAverage[a]})
                  .map((key,index) => {
                    if(completedReviewerIndividualQuestionsAverage[key] > 0) {
                      return (index < 5) ?
                        (
                          <div key={key} className="w-100 flex bt b--light-gray">
                            <div className="w-40 pr2">
                              <p>{this.state.questionDetails[key].questionTitle}</p>
                            </div>
                            <div className="w-20">
                              <p className="blue"><strong>Reviewers</strong></p>
                              <p className="blue"><strong>Self</strong></p>
                            </div>
                            <div className="w-40">
                              <div className="w-100"><p className="flex"><strong className="w-10">{completedReviewerIndividualQuestionsAverage[key]}</strong>
                                <span className="w-100 bg-light-gray">
                                  <span className="bg-blue h-100 db" style={{width: `${ (completedReviewerIndividualQuestionsAverage[key] / 5) * 100 }%` }}></span>
                                </span>
                              </p></div>
                              <div className="w-100"><p className="flex"><strong className="w-10">{this.state.userIndividualAnswers[key].toFixed(1)}</strong>
                                <span className="w-100 bg-light-gray">
                                  <span className="bg-blue h-100 db" style={{width: `${ (this.state.userIndividualAnswers[key].toFixed(0) / 5) * 100 }%` }}></span>
                                </span>
                              </p></div>
                            </div>
                          </div>
                        )
                        : ''
                    }

                  })
                : ''
              }
              </div>


              <div className="bb b--light-gray">
              <p><strong>Lowest Average Statements</strong></p>
              {
                /* Loop through completedReviewerIndividualQuestionsAverage (sorting in numerical order!) */
                (completedReviewerIndividualQuestionsAverage) ?
                Object
                  .keys(completedReviewerIndividualQuestionsAverage)
                  .sort(function(a,b){return completedReviewerIndividualQuestionsAverage[a] - completedReviewerIndividualQuestionsAverage[b]})
                  .map((key,index) => {
                    if(completedReviewerIndividualQuestionsAverage[key] > 0) {
                      return (index < 5) ? (
                        <div key={key} className="w-100 flex bt b--light-gray">
                          <div className="w-40 pr2">
                            <p>{this.state.questionDetails[key].questionTitle}</p>
                          </div>
                          <div className="w-20">
                            <p className="blue"><strong>Reviewers</strong></p>
                            <p className="blue"><strong>Self</strong></p>
                          </div>
                          <div className="w-40">
                            <div className="w-100"><p className="flex"><strong className="w-10">{completedReviewerIndividualQuestionsAverage[key]}</strong>
                              <span className="w-100 bg-light-gray">
                                <span className="bg-blue h-100 db" style={{width: `${ (completedReviewerIndividualQuestionsAverage[key] / 5) * 100 }%` }}></span>
                              </span>
                            </p></div>
                            <div className="w-100"><p className="flex"><strong className="w-10">{this.state.userIndividualAnswers[key].toFixed(1)}</strong>
                              <span className="w-100 bg-light-gray">
                                <span className="bg-blue h-100 db" style={{width: `${ (this.state.userIndividualAnswers[key].toFixed(0) / 5) * 100 }%` }}></span>
                              </span>
                            </p></div>
                          </div>
                        </div>
                      )
                      : ''
                    }

                  })
                : ''
              }
              </div>


              <div className="mv5">
                <h3 className="f5 gray i">Reflection</h3>
                <p>Based on this chart, what appear to be your strongest and least strong capability areas?</p>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <p>How does this align with your self perceptions?</p>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <p>Which capabilities may be the most relevant for you to look into further?</p>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              </div>











              <h1 className="f2 mv0 light-blue">Section 2.4:</h1>
              <h2 className="f2 mv0">Statements: greatest variations</h2>
              <p>This chart lists the statements with the greatest variations between your self-review and your colleagues' ratings. These may be either over-ratings or under-ratings compared to your colleagues' ratings.</p>
              <p>Statements that you have over-rated in comparison to your colleagues' ratings may indicate that you perceive these statements to be stronger than they appear to others. Statements where you have underrated in comparison to your colleagues may mean you are stronger in these areas than you think.</p>




              <div className="bb b--light-gray mv5">
              <p><strong>Statements which you have over-rated the most</strong></p>
              {
                /* Loop through completedIndividualQuestionsDifferences (sorting in numerical order!) */
                (completedIndividualQuestionsDifferences) ?
                Object
                  .keys(completedIndividualQuestionsDifferences)
                  .sort(function(a,b){return completedIndividualQuestionsDifferences[b] - completedIndividualQuestionsDifferences[a]})
                  .map((key,index) => {
                    if(completedIndividualQuestionsDifferences[key] > 0) {
                      return (index < 5) ? (
                        <div key={key} className="w-100 flex bt b--light-gray">
                          <div className="w-40 pr2">
                            <p>{this.state.questionDetails[key].questionTitle}</p>
                          </div>
                          <div className="w-10">
                            <p className="blue"><strong>Reviewers</strong></p>
                            <p className="blue"><strong>Self</strong></p>
                          </div>
                          <div className="w-50">
                            <div className="w-100"><p className="flex"><span className="w-100 bg-light-gray">
                                <span className="bg-blue h-100 db" style={{width: `${ (completedReviewerIndividualQuestionsAverage[key] / 5) * 100 }%` }}></span>
                              </span><strong className="w-30">{completedReviewerIndividualQuestionsAverage[key]}</strong>
                            </p></div>
                            <div className="w-100"><p className="flex"><span className="w-100 bg-light-gray">
                                <span className="bg-blue h-100 db" style={{width: `${ (this.state.userIndividualAnswers[key].toFixed(0) / 5) * 100 }%` }}></span>
                              </span><strong className="w-30">{this.state.userIndividualAnswers[key].toFixed(1)}(+{completedIndividualQuestionsDifferences[key]})</strong>
                            </p></div>
                          </div>
                        </div>
                      )
                      : ''
                    }
                  })
                : ''
              }
              </div>

              <div className="bb b--light-gray mv5">
              <p><strong>Statements which you have under-rated the most</strong></p>
              {
                /* Loop through completedIndividualQuestionsDifferences (sorting in numerical order!) */
                (completedIndividualQuestionsDifferences) ?
                Object
                  .keys(completedIndividualQuestionsDifferences)
                  .sort(function(a,b){return completedIndividualQuestionsDifferences[a] - completedIndividualQuestionsDifferences[b]})
                  .map((key,index) => {
                    // console.log("completedIndividualQuestionsDifferences")
                    // console.log(completedIndividualQuestionsDifferences)
                    //   console.log(this.state.questionDetails)
                    //   console.log(this.state.questionDetails)
                      return (index < 5) ? (
                        <div key={key} className="w-100 flex bt b--light-gray">
                          <div className="w-40 pr2">
                            <p>{this.state.questionDetails[key].questionTitle}</p>
                          </div>
                          <div className="w-10">
                            <p className="blue"><strong>Reviewers</strong></p>
                            <p className="blue"><strong>Self</strong></p>
                          </div>
                          <div className="w-50">
                            <div className="w-100"><p className="flex"><span className="w-100 bg-light-gray">
                                <span className="bg-blue h-100 db" style={{width: `${ (completedReviewerIndividualQuestionsAverage[key] / 5) * 100 }%` }}></span>
                              </span><strong className="w-30">{completedReviewerIndividualQuestionsAverage[key]}</strong>
                            </p></div>
                            <div className="w-100"><p className="flex"><span className="w-100 bg-light-gray">
                                <span className="bg-blue h-100 db" style={{width: `${ (this.state.userIndividualAnswers[key].toFixed(0) / 5) * 100 }%` }}></span>
                              </span><strong className="w-30">{this.state.userIndividualAnswers[key].toFixed(1)}({completedIndividualQuestionsDifferences[key]})</strong>
                            </p></div>
                          </div>
                        </div>
                      )
                      : ''
                  })
                : ''
              }
              </div>


              <div className="mv5">
                <h3 className="f5 gray i">Reflection</h3>
                <p>What possible reasons can you think of for the variations in the rating on the statements above?</p>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <p>How do the variations above compare with the other information in this report?</p>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
              </div>























              <p><br/><br/><br/></p>
              <h1 className="f2 mv0 light-blue page-break">Section 3:</h1>
              <h2 className="f1 mv0">Your Detailed Feedback</h2>
              <p>This section of your report breaks down all the feedback you have received by capability, and within each capability, by statement: you can see the average rating from each group of colleagues, as well as your self-review rating.</p>
              <p>Statements with close or similar ratings mean consistency of message from each group of colleagues. Consistently highly rated statements indicate strengths and you may wish to use these more. Consistently lower rated statements may indicate general areas where you may want to make changes.</p>
              <p>Statements where there is a big gap between colleague group ratings can suggest a mismatch between different colleagues' experiences in working with you, or different perceptions. You may want to think about why this may be the case.</p>
              <p>Use the Number of responses to check where there is a wide range of ratings from a group of colleagues. This may indicate some inconsistency in how you work with different colleagues even in the same group.</p>
              <p>The bars show the following:</p>
              <ul>
                <li>The self bar (red) shows the self-review rating.</li>
                <li>The various relationship bars (other colours) show the average rating from each relationship group</li>
              </ul>

              {
                /* Loop through each Section and display table of overall and individual Question values */
                Object
                  .keys(this.state.sectionDetails)
                  .map((key) => {
                    let sectionID = key
                    return (this.state.sectionDetails[sectionID].title == "Additional") ?
                    (
                      <div className="page-break">
                        <h3 className="f5 gray">Additional Comments</h3>
                        <p className="blue"><strong>Self:</strong></p>
                        <p>{ this.state.userComments[sectionID] }</p>

                        <p className="blue"><strong>Colleagues:</strong></p>
                        {
                          Object
                            .keys(this.state.completedReviewerComments[sectionID])
                            .map((key) => {
                              return (this.state.completedReviewerComments[sectionID][key]) ? (
                                <div>
                                  <p>{ this.state.completedReviewerComments[sectionID][key] }</p><hr className="bb bt-0 b--light-silver mv3" />
                                </div>
                              ) : ''

                            })
                        }


                      </div>

                    )
                    :
                    (
                      <div key={sectionID} className="mb6 page-break">
                        <div className="ba b--moon-gray mv5 mb10">
                        <div className="row flex bg-light-gray pt3">
                          <div className="w-30 pv3"></div>
                          <div className="w-70">
                            <div className="w-100 flex flex-wrap">
                              <div className="w-20 pv3"><strong>Relationship</strong></div>
                              <div className="w-50 pv3"><strong>Average Rating</strong></div>
                              <div className="w-30 pv3">
                                <div className="tc">
                                  <strong>Number of responses</strong>
                                </div>
                                <div className="w-100 flex">
                                  <p className="w-100 mv0 tc">5</p>
                                  <p className="w-100 mv0 tc">4</p>
                                  <p className="w-100 mv0 tc">3</p>
                                  <p className="w-100 mv0 tc">2</p>
                                  <p className="w-100 mv0 tc">1</p>
                                  <p className="w-100 mv0 tc">?</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row pa2 flex bt bb b--moon-gray">
                          <div className="w-30">
                            <p className="ma0 f6"><strong>{ this.state.sectionDetails[key].title }</strong></p>
                          </div>
                          <div className="w-70">
                            <div className="w-100 flex flex-wrap">
                              <div className="w-20 mv1">
                                <p className="ma0 blue"><strong>Self</strong></p>
                              </div>
                              <div className="w-50 mv1">
                                <div className="w-100"><p className="flex ma0"><strong className="w-10">{(this.state.userAnswers[key] * 5).toFixed(1)}</strong>
                                  <span className="w-100 bg-light-gray">
                                    <span className="bg-blue h-100 db" style={{width: `${ (this.state.userAnswers[key]) * 100 }%` }}></span>
                                  </span>
                                </p></div>
                              </div>
                              <div className="w-30 pv1">
                                <div className="w-100 flex">
                                  {
                                    Object
                                      .keys(this.state.userResponses[sectionID])
                                      .reverse()
                                      .map((key) => {
                                        return (
                                          <p className="w-100 mv0 tc"><strong>{ this.state.userResponses[sectionID][key] }</strong></p>
                                        )
                                      })
                                  }
                                </div>
                              </div>
                            </div>

                            {
                              Object
                                .keys(this.state.reviewerAnswers)
                                .map((key,index) => {
                                  // console.log(key)
                                  return (this.state.reviewerAnswers[key][sectionID]) ?
                                  (
                                    <div key={key} className="w-100 flex">
                                      <div className="w-20 mv1">
                                        <p className="ma0" style={{color: relationshipColor(key)}}><strong>{ convertRelationships(key) }</strong></p>
                                      </div>
                                      <div className="w-50 mv1">
                                        <div className="w-100"><p className="flex ma0"><strong className="w-10" style={{color: relationshipColor(key)}}>{ (this.state.reviewerAnswers[key][sectionID] * 5).toFixed(1) }</strong>
                                          <span className="w-100 bg-light-gray">
                                            <span className="bg-blue h-100 db" style={{background: relationshipColor(key), width: `${ (this.state.reviewerAnswers[key][sectionID]).toFixed(1) * 100 }%` }}></span>
                                          </span>
                                        </p></div>
                                      </div>
                                      <div className="w-30 pv1">
                                        <div className="w-100 flex">
                                          {
                                            Object
                                              .keys(this.state.reviewerResponses[key][sectionID])
                                              .reverse()
                                              .map((a) => {
                                                return (
                                                  <p className="w-100 mv0 tc"><strong>{ this.state.reviewerResponses[key][sectionID][a] }</strong></p>
                                                )
                                              })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  ) : ''
                                })

                            }
                          </div>
                        </div>
                        {
                          Object
                            .keys(this.state.sectionDetails[sectionID].questions)
                            .map((key) => {
                              let questionID = key
                              // console.log(this.state.userIndividualResponses[questionID]);
                              return (
                                <div key={questionID} className="row pa2 flex bt b--moon-gray">
                                  <div className="w-30 pr3">
                                    <p className="ma0">{ this.state.sectionDetails[sectionID].questions[questionID].questionTitle }</p>
                                  </div>
                                  <div className="w-70 flex flex-wrap">
                                    <div className="w-20 mv1">
                                      <p className="ma0 blue"><strong>Self</strong></p>
                                    </div>
                                    <div className="w-50 mv1">
                                      <div className="w-100"><p className="flex ma0"><strong className="w-10">{this.state.userIndividualAnswers[questionID].toFixed(1)}</strong>
                                        <span className="w-100 bg-light-gray">
                                          <span className="bg-blue h-100 db" style={{width: `${ (this.state.userIndividualAnswers[questionID].toFixed(0) / 5) * 100 }%` }}></span>
                                        </span>
                                      </p></div>
                                    </div>
                                    <div className="w-30 mv1">

                                      <div className="w-100 flex flex-row-reverse">
                                          {

                                            Object
                                              .keys(this.state.userResponses[sectionID])
                                              .map((key, i) => {
                                                return (
                                                  <p className="w-100 mv0 tc"><strong>{ (this.state.userIndividualAnswers[questionID] == i) ? '1' : '0' }</strong></p>
                                                )
                                              })
                                          }
                                      </div>

                                    </div>


                                    {
                                      Object
                                        .keys(this.state.completedReviewerAnswersByRelationship[questionID])
                                        .map((key,index) => {
                                          let relationshipID = key
                                          return (
                                            <div key={relationshipID} className="w-100 flex">
                                              <div className="w-20 mv1">
                                                <p className="ma0" style={{color: relationshipColor(relationshipID)}}><strong>{ convertRelationships(relationshipID) }</strong></p>
                                              </div>
                                              <div className="w-50 mv1">
                                                <div className="w-100"><p className="flex ma0"><strong className="w-10" style={{color: relationshipColor(relationshipID)}}>{ average( this.state.completedReviewerAnswersByRelationship[questionID][relationshipID] ).toFixed(1) }</strong>
                                                  <span className="w-100 bg-light-gray">
                                                    <span className="bg-blue h-100 db" style={{background: relationshipColor(relationshipID), width: `${ (average( this.state.completedReviewerAnswersByRelationship[questionID][relationshipID] ).toFixed(1) / 5) * 100 }%` }}></span>
                                                  </span>
                                                </p></div>
                                              </div>
                                              <div className="w-30 mv1">

                                                <div className="w-100 flex flex-row-reverse">
                                                  {
                                                    Object
                                                      .keys(this.state.userResponses[sectionID])
                                                      .map((key, i) => {
                                                        let count = 0
                                                        Object
                                                          .keys(this.state.completedReviewerAnswersByRelationship[questionID][relationshipID])
                                                          .map((key, index) => {
                                                            if( this.state.completedReviewerAnswersByRelationship[questionID][relationshipID][index] == i ) {
                                                              count++
                                                            }
                                                          })

                                                        return (
                                                          <p className="w-100 mv0 tc">{ count }</p>
                                                        )
                                                      })
                                                  }
                                                </div>

                                              </div>
                                            </div>
                                          )
                                        })

                                    }

                                  </div>
                                </div>
                              )
                            })
                        }



                        </div>
                        <div className="mv5">
                          <h3 className="f5 gray i">What are the key themes or messages from the feedback in this section?</h3>
                          <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                          <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                          <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                          <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                          <div className="w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3"></div>
                        </div>
                      </div>
                    )

                  })
              }




            </div>
          </div>
          </PDFExport>
        </div>
      )
    } else {
      return (
        <div>
          <canvas ref={this.hiddenCanvas}></canvas>
          <LoadingState />
        </div>
      )
    }

  }
}

//
// export default withAuthorisation(authCondition)(User360Report);
export default User360Report
