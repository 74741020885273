import React from 'react';
import SignUpForm from '../components/signupform'
import SiteHeader from '../components/site-header'
import { compose } from 'recompose'
import withSuperUser from '../components/withsuperuser'
const authCondition = (authUser) => !!authUser
//

const SignUpPage = ({ history }) =>
  <main>
    <SiteHeader />
    <section className="mw8-l center pa4 ph4-l">
      <h1>Super Admin - Register</h1>
      <p>Create a new Account using the form below</p>
      <SignUpForm history={ history } />
    </section>
  </main>

export default compose(
  withSuperUser(authCondition),
)(SignUpPage)
