import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../../firebase'
import SiteFooterBrand from '../../../components/site-footer-brand'
import SiteHeader from '../../../components/site-header'
import SiteNav from '../../../components/site-nav'
import withAuthorisation from '../../../components/withauthorisation'
const authCondition = (authUser) => !!authUser
//

class Edit360Standard extends Component {
  constructor() {
    super()

    let editLink
    let editLinkQuestionnaire
    let editLinkBrand

    this.state = {
      current360: null,
      current360ID: null,
      loaded360: false
    }
  }
  componentDidMount() {
    const { match: { params } } = this.props
    window.scrollTo(0,0)

    // this.editLinkBasic = `/dashboard/360s/${params.id}/edit/basic/`
    this.adminLink = `/dashboard/`
    this.editLink = `/dashboard/360s/${params.id}/edit/`
    this.editLinkRelationships = `/dashboard/360s/${params.id}/edit/relationships/`
    this.editLinkBrand = `/dashboard/360s/${params.id}/edit/brand/`

    db.doGetCurrent360(params.id).then((data)=> {
      if(data.val()) {
        this.setState({
          current360: data.val(),
          current360ID: data.key,
          loaded360: true
        })
      }
    })

  }
  render() {


    return (
      <main>
        <SiteHeader />
        <SiteNav />
        {
          (this.state.loaded360) ?
            (
              <section className="mw8-l center pa4 ph4-l">
                <p><Link to={this.adminLink}>Dashboard</Link> &rarr; <Link to={this.editLink}>{ this.state.current360.title }</Link> &rarr; Standard Settings</p>

                <h1>{ this.state.current360.title }: Standard Settings</h1>

                <div className="flex items-center justify-between w100 bg-white shadow-2 pa4 mv4">
                  <div className="w-70">
                    <p className="ma0"><strong>Edit Relationships</strong><br/>Add/Remove/Edit colleague relationships</p>
                  </div>
                  <div className="w-20">
                    <Link className="btn" to={ this.editLinkRelationships }>Start</Link>
                  </div>
                </div>


                <div className="flex items-center justify-between w100 bg-white shadow-2 pa4 mv4">
                  <div className="w-70">
                    <p className="ma0"><strong>Edit email text</strong><br/>Edit the content of each email reminder</p>
                  </div>
                  <div className="w-20">
                    <Link className="btn" to={ this.editLinkBrand }>Start</Link>
                  </div>
                </div>
              </section>
            ) :
            (
              "Loading"
            )
        }
        <SiteFooterBrand />
      </main>
    );
  }
}

//
export default withAuthorisation(authCondition)(Edit360Standard);
