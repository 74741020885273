import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthUserContext from './authusercontext';
import { firebase } from '../firebase';

const withAuthorisation = (authCondition) => (Component) => {
  class WithAuthorisation extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged(authUser => {
        console.log(authUser)
        if (!authCondition(authUser)) {
          this.props.history.push("/");
        } else {
          if(authUser.email === "admin@boomerang-360.com") {
            if(this.props.history.location.pathname === "/") {
              this.props.history.push("/admin/");
            }
          } else {
            if(this.props.history.location.pathname === "/") {
              this.props.history.push("/dashboard/");
            }
          }

          // console.log('aaaa');
          // this.props.foo="bar"
        }
      });
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser => authUser
            ? <Component userEmail={authUser.email} {...this.props} />
            : <Component />
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorisation);

}

export default withAuthorisation;
