import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../firebase'

class RelationshipForm extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }
  handleSubmit(e) {
    let relationshipData = {}
    relationshipData = {
      'name': this.relationshipName.value,
      'colour': this.relationshipColour.value,
      'description': this.relationshipDescription.value
    }
    if(this.relationshipKey.value == this.props.relationshipKey) {
      console.log(relationshipData)
      // Update relationship
      db.doUpdateRelationship(this.props.current360Id, this.props.relationshipKey, relationshipData)
    } else {
      // Remove and recreate relationship (as the Key has changed)
      db.doDeleteRelationship(this.props.current360Id, this.props.relationshipKey).then((data) => {
        db.doCreateNewRelationship(this.props.current360Id, this.relationshipKey.value, relationshipData)
      })
    }


    // console.log(relationshipData)


    e.preventDefault();
  }
  handleChange(e) {
    console.log(this.state.current360);
    // let questionData = {
    //   'answerType': this.answerType.value,
    //   'questionTitle': this.questionTitle.value,
    //   'required': this.questionRequired.value
    // }
    e.preventDefault();
  }
  render() {
    return (

      <form action="/" className="pv3" onSubmit={this.handleSubmit}>
        <p><strong>Relationship #{this.props.relationshipNum}</strong></p>
        <div className="flex justify-between">

          <div className="w-10">
            <label className="db">Label</label>
            <input maxLength="3" required ref={(input) => this.relationshipKey = input} type="text" className="w-100" defaultValue={ this.props.relationshipKey } />
          </div>
          <div className="w-20">
            <label className="db">Name</label>
            <input required ref={(input) => this.relationshipName = input} type="text" className="w-100" defaultValue={ this.props.relationship.name } />
          </div>
          <div className="w-20">
            <label className="db">Colour</label>
            <input required ref={(input) => this.relationshipColour = input} type="text" className="w-100" defaultValue={ this.props.relationship.colour } />
          </div>
          <div className="w-40">
            <label className="db">Description</label>
            <textarea ref={(input) => this.relationshipDescription = input} className="w-100" defaultValue={ this.props.relationship.description }></textarea>
          </div>

        </div>

        <button type="submit" className="btn">Save Relationship</button>

        <hr className="mt4" />

      </form>

    );
  }
}

//
export default RelationshipForm;
