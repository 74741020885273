import React from 'react'
// import { withRouter } from 'react-router-dom'
import SignInForm from '../components/signinform'
import SiteHeader from '../components/site-header'
import withAuthorisation from '../components/withauthorisation'
const authCondition = (authUser) => !!authUser
//

const SignInPage = ({ history }) =>
  <main>
    <section className="mw8-l center pa4 ph4-l">
      <SignInForm history={ history } />
    </section>
  </main>

export default withAuthorisation(authCondition)(SignInPage);
