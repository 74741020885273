import React, { Component } from 'react'
import { db } from '../firebase'

class StaticQuestion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      questionTitle: this.props.questionTitle,
      answerType: this.props.answerType,
      questionRequired: this.props.questionRequired,

      questionID: this.props.index,
      questionnaireID: this.props.questionnaireID,
      sectionID: this.props.sectionID,

      working: false,

      formClass: "flex br3 mb3 mt2 pv1 bg-light-blue",
      selectClass: "bg-transparent bn gray",
      inputClass: "mv2 pv2 w-100 bg-transparent input-reset bn gray outline-0",
      inputDateClass: "mv2 pv2 bg-transparent input-reset bn gray outline-0",
    }

    this.updateQuestion = this.updateQuestion.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
  }
  updateQuestion(e) {
    let questionData = {
      'answerType': this.answerType.value,
      'questionTitle': this.questionTitle.value,
      'questionDate': this.questionDate.value,
      'required': this.questionRequired.value
    }

    this.setState({
      working: true
    })

    db.updateQuestion(this.state.questionnaireID, this.state.sectionID, this.state.questionID, questionData).then((data) => {

      this.setState({
        editing: false,
        working: false,
        formClass: "flex br3 mb3 mt2 pv1 bg-light-blue",
        selectClass: "bg-transparent bn gray",
        inputClass: "mv2 pv2 w-100 bg-transparent input-reset bn gray outline-0",
        inputDateClass: "mv2 pv2 bg-transparent input-reset bn gray outline-0",
      })
    })

    e.preventDefault()
  }
  deleteQuestion(e, questionID) {

    console.log('deleteQuestion ' + questionID);

    db.doDeleteQuestion(this.state.questionnaireID, this.state.sectionID, this.state.questionID)

    e.preventDefault()
  }
  editQuestion(e) {
    this.setState({
      editing: true,
      formClass: "flex br3 mb3 mt2 pv1 bg-navy",
      selectClass: "bg-transparent bn white",
      inputClass: "mv2 pv2 w-100 bg-transparent input-reset bn white outline-0",
      inputDateClass: "mv2 pv2 bg-transparent input-reset bn white outline-0",
    })
    this.questionTitle.focus()
    e.preventDefault()
  }

  render() {
    return (
      <form action="/" className={this.state.formClass} onSubmit={this.updateQuestion}>
        <div className="w-60 pl3">
          <input disabled={!this.state.editing} ref={(input) => this.questionTitle = input} className={this.state.inputClass} type="text" defaultValue={this.state.questionTitle} />
        </div>

        <div className="w-40 flex justify-around items-center">
          <div className="bl w-100 tc b--gray">
            <select disabled={!this.state.editing} ref={(input) => this.answerType = input} className={this.state.selectClass} defaultValue={this.state.answerType}>
              <option value="MultiChoice">Rating</option>
              <option value="FreeText">Text</option>
              <option value="Date">Date</option>
            </select>
          </div>

          <div className="bl w-100 tc b--gray">
            <select disabled={!this.state.editing} ref={(input) => this.questionRequired = input} className={this.state.selectClass} defaultValue={this.state.questionRequired}>
              <option value="true">Mandatory</option>
              <option value="false">Optional</option>
            </select>
          </div>

          <div className="bl w-100 tc b--gray">
            {
              (this.state.editing) ?
                <button className="" disabled={(this.state.working) ? 'disabled' : ''} onClick={(e) => this.updateQuestion(e)}>Save</button>

              :
              <div>
                <button className="" type="submit" onClick={(e) => this.editQuestion(e)}>Edit</button>
                <button className="" onClick={(e) => this.deleteQuestion(e)}>Delete</button>
              </div>

            }



          </div>
        </div>
      </form>
    )
  }

}

export default StaticQuestion
