import React, { Component } from 'react';
import { compose } from 'recompose'
import { db } from '../firebase'
import withAuthorisation from '../components/withauthorisation';
import withUser360s from '../components/withuser360s';
import { Link } from 'react-router-dom'
import List360 from '../components/list360'
import SiteHeader from '../components/site-header'
import SiteNav from '../components/site-nav'
import Breadcrumbs from '../components/breadcrumbs'
import SiteFooterBrand from '../components/site-footer-brand'
import Modal from '../components/global/modal'
import Autocomplete from 'react-autocomplete'
import { lightBoxStyles } from '../constants/lightbox.js'

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
const authCondition = (authUser) => !!authUser
// console.log(authUser)
// const aaa = foo

class Dashboard extends Component {
  constructor(props) {
    super(props)
    // alert("!")
    console.log(this.props);

    this.state = {
      loading: true,
      settingUpProject: false,
      noprojects: true,
      selectedAccount: null,
      accountId: this.props.match.params.accountId,
      accounts: this.props.userDetails.accounts,
      userDetails: this.props.userDetails,
      accountTitle: this.props.accountTitle,
      user360s: {},
      clientList: "",
      clientName: "",
      showNew360Modal: false,
      new360Type: "360"
    }

    this.createNew360 = this.createNew360.bind(this);
    this.closeNew360Modal = this.closeNew360Modal.bind(this);
    this.openNew360Modal = this.openNew360Modal.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0)

    let clientList = {}

    db.doGet360s( this.state.accounts[0].id ).on('value', data => {
      let user360s = data.val()
      console.log(user360s)

      if(user360s) {
        // Loop through clients list and create list of 360s grouped by client name...
        Object
          .keys(user360s)
          .map(key => {
            let clientName = user360s[key]["client"]
            if(clientName) {
              if( !clientList[ clientName ] )
                clientList[[clientName]] = []

              clientList[clientName].push( user360s[key] )
            }
          })


          this.setState({
            user360s: user360s,
            clientList: clientList,
            noprojects: false,
            loading: false
          })
      } else {
        this.setState({
          noprojects: true,
          loading: false
        })
      }
    })


  }

  closeNew360Modal() {
    this.setState({ showNew360Modal: false });
  }
  openNew360Modal(type) {
    this.setState({ showNew360Modal: true, new360Type: type });
  }


  createNew360(e) {

    const itemsRef = db.doCreate360(this.state.accountId, this.title.value, this.state.clientName, this.state.new360Type).then((data) => {
      let new360ID = data.key
      this.setState({
        clientName: ""
      })
      console.log("new360ID = "+new360ID);

      db.doCreateNewQuestionnaire(this.state.accountId, this.state.new360Type).then((data) => {
        let newQuestionnaireID = data.key

        console.log("newQuestionnaireID" + newQuestionnaireID);
        console.log("new360ID" + new360ID);

        db.doAssignQuestionnaireTo360(this.state.accountId, newQuestionnaireID, new360ID).then((data) => {
          this.setState({
            settingUpProject: false
          })
          // Close pop-up
          this.closeNew360Modal()

          // Forward to edit page
          this.props.history.push(`/dashboard/${this.state.accountId}/360s/${new360ID}/edit/`)
        })

      })
    }).catch(error => {
      this.setState(byPropKey('error', error))
    })

    e.preventDefault();
  }

  render() {
    let incomplete360s = {}
    let incompleteGroupedByClient = {}
    let active360s = {}
    let achived360s = {}
    let clientItemList = []


    return (
      <main>
        <SiteHeader accountData={this.props.accountData} fname={this.props.userDetails.fname} lname={this.props.userDetails.lname} />
        <SiteNav accountData={this.props.accountData} />
        <section className="bg-blue pv4">
          <div className="mw7-l center tc pa4 ph4-l white">
            <h1 className="white">Welcome to your <strong>360 Admin Dashboard</strong></h1>
            <p className="white">Your dashboard provides you with instant access to all the 360 feedback projects associated to your account.</p>
            <p className="white">You can set up a new 360 project below. Alternatively, you can add new groups/cohorts to a project, by clicking the 'Edit this 360 button'.</p>
            <p className="white">Projects that are yet to launch are located under 'Projects that require attention'. Any new project you add to your dashboard will automatically load under this heading until they are live.</p>
            <p className="white">All your live 360 projects are located under 'Your active 360s'.</p>
            <p className="white">Finally, you can archive, duplicate or delete projects below. If you choose to archive projects, these will be moved under the 'Your archived 360 projects' at the foot of the page.</p>
          </div>
        </section>
        {
          (this.state.loading) ?
            (
              <p>Loading</p>
            )
          :
          (
            <section className="mw8-l center pa4 ph4-l">
              <Breadcrumbs
                current360={null}
                userDetails={this.state.userDetails}
                accountTitle={this.state.accountTitle}
                links={[ {
                  title: "Dashboard",
                  to: "/dashboard/"
                },{
                  title: this.state.accountTitle,
                  to: null
                } ]} />

              {
                // map achived/incomplete/active projects
                Object
                  .keys(this.state.user360s)
                  .reverse()
                  .map((key) => {
                    if(this.state.user360s[key]["archived"]) {
                      achived360s[[key]] = this.state.user360s[key]
                    } else {
                      if(this.state.user360s[key]["statusCode"] < 2) {
                        incomplete360s[[key]] = this.state.user360s[key]
                      } else {
                        active360s[[key]] = this.state.user360s[key]
                      }
                    }

                  })
              }


              {
                (Object.keys(incomplete360s).length) ? (
                  <div>
                    <div className="mv4">
                      <h1 className="mb0">Projects that require your attention</h1>
                      <p>These projects are not yet live. Under the project title, you can see the fix that is required, before you can set the project live. You can click the 'Edit this 360' button to make the necessary changes.</p>
                    </div>
                    {
                      Object
                        .keys(incomplete360s)
                        .map((key) => {
                          if( !incompleteGroupedByClient[ [ this.state.user360s[key].client ] ] ) {
                            incompleteGroupedByClient[ [ this.state.user360s[key].client ] ] = {}
                          }
                          incompleteGroupedByClient[[ this.state.user360s[key].client ]][[ key ]] = incomplete360s[key]
                        })
                    }
                    {
                      Object
                        .keys(incompleteGroupedByClient)
                        .sort()
                        .map((key) => {
                          return (
                            <div className="client-group" key={ key }>
                              <p><strong>{ key }</strong></p>
                              {
                                Object
                                  .keys(incompleteGroupedByClient[key])
                                  .map((key) => {
                                    return (
                                      <List360
                                        key={key}
                                        index={key}
                                        userDetails={this.props.userDetails}
                                        selectedAccount={this.state.accountId}
                                        accountTitle={this.state.accountTitle}
                                        current360={this.state.user360s[key]}
                                        accountId={this.state.accountId}
                                      />
                                    )
                                  })
                              }
                            </div>
                          )
                        })
                    }

                  </div>
                ) : ''
              }

              {
                (Object.keys(active360s).length) ? (
                  <div>
                    <div className="mv4">
                      <h1 className="mb0">Live 360 projects</h1>
                      <p>These projects are actively collecting review data</p>
                    </div>
                    {
                      Object
                        .keys(active360s)
                        .map((key) => {
                          return (
                            <List360
                              key={key}
                              index={key}
                              current360={this.state.user360s[key]}
                              accountId={this.state.accountId} />
                          )
                        })
                    }
                  </div>
                ) : ''
              }


              <div className="flex w-100 justify-around">
                <div className="w-100 mh2 bg-blue pv4 ph4 mv5 white flex flex-column justify-between br2">
                  <div className="mb2">
                    <p className="f3 white ma0"><strong>Create a new 360 project</strong></p>
                    <p className="white ma0">Use this section to create a brand new 360 or Goal Tracker project</p>
                  </div>
                  <div>
                    <button onClick={ () => this.openNew360Modal("360") } className="btn btn--white">Start Now</button>
                  </div>
                </div>
                <div className="w-100 mh2 bg-blue pv4 ph4 mv5 white flex flex-column justify-between br2">
                  <div className="mb2">
                    <p className="f3 white ma0"><strong>Create a new Goal Tracker</strong></p>
                    <p className="white ma0">Use this section to create a brand new Goal tracker</p>
                  </div>
                  <div>
                    <button onClick={ () => this.openNew360Modal("Goal Tracker") } className="btn btn--white">Start Now</button>
                  </div>
                </div>
              </div>

              {
                (Object.keys(achived360s).length) ? (
                  <div>
                    <div className="mv4">
                      <h1 className="pt4">Your archived 360 projects</h1>
                      <p>These 360 projects are currently archived, you are still able to view all the Reports associated to these projects</p>
                    </div>
                    {
                      Object
                        .keys(achived360s)
                        .map((key) => {
                          return (
                            <List360
                              key={key}
                              index={key}
                              current360={this.state.user360s[key]}
                              accountId={this.state.accountId}
                            />
                          )
                        })
                    }
                  </div>
                ) : ''
              }


            </section>
          )

        }


        <SiteFooterBrand />


        {
          Object
            .keys(this.state.clientList)
            .map((key, i) => {
              clientItemList.push({
                id: i,
                label: key
              })
            })
        }

        <Modal
          isOpen={ this.state.showNew360Modal }
          contentLabel="Add a new 360"
          onClose={ () => this.closeNew360Modal() }
          content={
          <div>
            <h2>Create a new { this.state.new360Type }</h2>
            <form onSubmit={this.createNew360} >
              <label htmlFor="title" className="f6 b db mb2">Project Name</label>
              <input required ref={(input) => this.title = input} aria-describedby="name-desc" className="input-reset ba b--black-20 pa2 mb4 db w-100" type="text" placeholder="Name your 360" />
              <label htmlFor="client" className="f6 b db mb2">Client</label>
              {
                (this.state.showNew360Modal && !this.props.accountData.clientname) ? (


                  <Autocomplete
                    ref={el => this.client = el}
                    items={clientItemList}
                    shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                    getItemValue={item => item.label}
                    renderItem={(item, isHighlighted) =>
                      <div key={item.id} className="input-reset bb bl br b--black-20 pa2 db w-100" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {item.label}
                      </div>
                    }
                    renderInput={(props) =>
                      <input required aria-describedby="client-desc" {...props} className="input-reset ba b--black-20 pa2 mb4 db w-100" type="text" placeholder="Select a Client, or create new" />
                    }
                    value={this.state.clientName}
                    onChange={e => this.setState({ clientName: e.target.value })}
                    onSelect={value => this.setState({ clientName: value })}
                    wrapperStyle={{
                      position: 'relative'
                    }}
                    menuStyle={{
                      top: '100%',
                      left: 0,
                      position: 'absolute'
                    }}
                  />
                ) : <input required aria-describedby="client-desc" ref={el => this.client = el} defaultValue={ this.props.accountData.clientname } className="input-reset ba b--black-20 pa2 mb4 db w-100" type="text" placeholder="Select a Client, or create new" />
              }
              <button className="b ph3 pv2 input-reset ba b--black bg-transparent pointer f6 dib" disabled={ this.state.settingUpProject } type="submit">Submit</button>
            </form>
          </div>
          }
        />
      </main>
    );
  }
}
//

// export default withAuthorisation(authCondition)(Dashboard);

export default compose(
  withAuthorisation(authCondition),
  withUser360s()
)(Dashboard)
