import React, { Component } from 'react'

class NoMatch extends Component {
  render() {
    return (
      <h4>Page not found</h4>
    );
  }
}

export default NoMatch;
