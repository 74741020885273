import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../../firebase'
import Autocomplete from 'react-autocomplete'
import LoadingState from '../../../components/global/loading-state'
import Breadcrumbs from '../../../components/breadcrumbs'
import SiteFooter from '../../../components/site-footer'
import SiteHeader from '../../../components/site-header'
import SiteNav from '../../../components/site-nav'
import StaticQuestion from '../../../components/staticquestion'

//
import { compose } from 'recompose'
import withAuthorisation from '../../../components/withauthorisation'
import withUser360s from '../../../components/withuser360s'
const authCondition = (authUser) => !!authUser
//

let existing360ItemList = []

class Edit360QuestionnaireSection extends Component {
  constructor(props) {
    super(props)

    const { match: { params } } = this.props

    this.state = {
      loading: true,
      id: params.questionnaireSectionId,
      current360: this.props.current360,
      userDetails: this.props.userDetails,
      accountTitle: this.props.accountTitle,
      current360Id: params.id,
      accountId: params.accountId,
    }

    this.addQuestion = this.addQuestion.bind(this)
    this.updateTitle = this.updateTitle.bind(this);
    this.updateDesc = this.updateDesc.bind(this);
  }

  componentDidMount() {
    console.log(this.state.accountId, this.state.current360.questionnaireID, this.state.id);
    db.doGetSectionQuestions(this.state.accountId, this.state.current360.questionnaireID, this.state.id).on('value', data => {
      console.log(data.val());
      if(data.val()) {
        this.setState({
          loading: false,
          section: data.val()
        })
      }
    })
  }

  addQuestion(e) {
    let questionData = {
      'answerType': this.answerType.value,
      'questionTitle': this.questionTitle.value,
      'required': this.questionRequired.value
    }

    db.doCreateNewQuestion(this.state.accountId, this.state.current360.questionnaireID, this.state.id, questionData)

    e.target.reset()

    e.preventDefault()
  }

  updateTitle(e) {
    db.doUpdateSectionTitle(this.state.accountId, this.state.current360.questionnaireID, this.state.id, e.target.value)
  }

  updateDesc(e) {
    db.doUpdateSectionDesc(this.state.accountId, this.state.current360.questionnaireID, this.state.id, e.target.value)
  }



  render() {
    // const { match: { params } } = this.props
    // let coreSettingsLink = `/dashboard/360s/${params.id}/edit/basic/`
    // alert(coreSettingsLink);
    return (
      <main>
        <SiteHeader />
        <SiteNav />
        {
          (this.state.loading) ?
            (
              <LoadingState />
            ) :
            (
              <section className="mw8-l center pa4 ph4-l">
              <Breadcrumbs
                current360={this.state.current360}
                userDetails={this.state.userDetails}
                accountTitle={this.state.accountTitle}
                accountData={this.props.accountData}
                links={[ {
                  title: "Dashboard",
                  to: "/dashboard/"
                },{
                  title: this.state.accountTitle,
                  to: "../../../../"
                }, {
                  title: this.state.current360.title,
                  to: "../../"
                }, {
                  title: "Core Settings",
                  to: "../../"
                }, {
                  title: "Questionnaire setup",
                  to: "../"
                }, {
                  title: "Editing Section",
                  to: null
                } ]} />

                <h1>{ this.state.current360.title }: Questionnaire setup</h1>

                <div className="bg-white mv4 ph4 pv4 shadow-2 br2">
                  {
                    (this.state.current360.type == "Goal Tracker") ?
                      <div>
                        <p><strong>Editing Goal Tracker step</strong></p>

                      </div>
                     :
                      <div>
                      <p><strong>Editing Questionnaire Competency</strong></p>
                      </div>

                  }


                  <div className="accordion__item mb2">
                    <label className="mb2 db">Title:</label>
                    <input required onBlur={this.updateTitle} type="text" className="input-reset ba b--black-20 bg-light-blue pa2 mb4 db w-100 outline-0" defaultValue={ this.state.section.title } />
                    <label className="mb2 db">Description:</label>
                    <textarea onBlur={this.updateDesc} rows="4" className="input-reset ba b--black-20 bg-light-blue pa2 mb4 db w-100 outline-0" defaultValue={ this.state.section.description } />


                    <div className="flex">
                    <div className="w-60 tc">
                    <p className="bl br b--light-silver">Statement</p>
                    </div>

                    <div className="w-40 flex justify-around">
                    <p className="br w-100 tc b--light-silver">Type</p>
                    <p className="br w-100 tc b--light-silver">Setting</p>
                    <p className="br w-100 tc b--light-silver">Action</p>
                    </div>
                    </div>


                    <form action="/" className="flex br3 mb3 mt2 pv2 bg-navy" onSubmit={this.addQuestion}>
                    <div className="w-60 pl3">
                    <input required ref={(input) => this.questionTitle = input} className="mv2 pv2 w-100 bg-transparent input-reset bn white outline-0" type="text" placeholder="Type a new question here" />
                    </div>

                    <div className="w-40 flex justify-around items-center">
                    <div className="bl w-100 tc b--gray">
                    <select ref={(input) => this.answerType = input} className="bg-transparent bn white">
                    <option value="MultiChoice">Rating</option>
                    <option value="FreeText">Text</option>
                    </select>
                    </div>

                    <div className="bl w-100 tc b--gray">
                    <select ref={(input) => this.questionRequired = input} className="bg-transparent bn white">
                    <option value="true">Mandatory</option>
                    <option value="false">Optional</option>
                    </select>
                    </div>

                    <div className="bl w-100 tc b--gray">
                    <button className="" type="submit">+</button>
                    </div>
                    </div>
                    </form>

                    {
                      (this.state.section.questions) ?
                      Object
                      .keys(this.state.section.questions)
                      // .reverse()
                      .map((key) => {
                        return (
                          <StaticQuestion key={key} index={key}
                            questionTitle={this.state.section.questions[key].questionTitle}
                            answerType={this.state.section.questions[key].answerType}
                            questionRequired={this.state.section.questions[key].required}
                            questionDate={this.state.section.questions[key].questionDate}
                            questionnaireID={this.state.current360.questionnaireID}
                            sectionID={this.state.id}
                             />
                        )
                      })
                      : ""
                    }
                  </div>


                </div>

                <Link to={{ pathname: "../", current360: this.state.current360, userDetails: this.state.userDetails, accountTitle: this.state.accountTitle }} className="f6 link ph4 pv3 dib white bg-green br2">Save and Return to Questionnaire</Link>

              </section>

            )
        }
      </main>
    )

  }
}

//
export default compose(
  withAuthorisation(authCondition),
  withUser360s()
)(Edit360QuestionnaireSection)
