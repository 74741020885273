import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class BreadCrumbs extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <p className="mb4 mt0">
      {
        Object
          .keys(this.props.links)
          .map((key, i) => {
            return (
              <span key={key}>
              {
                (i > 0) ? <span>&nbsp;&rarr;&nbsp;</span> : ''
              }
              {
                (this.props.links[key].to) ?
                  <Link to={{ pathname: this.props.links[key].to, accountData: this.props.accountData, current360: this.props.current360, userDetails: this.props.userDetails, accountTitle: this.props.accountTitle }}>{ this.props.links[key].title }</Link>
                : this.props.links[key].title
              }
              </span>
            )
          })
      }
      </p>
    );
  }
}

export default BreadCrumbs
