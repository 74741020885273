import React from 'react';
import { auth } from '../firebase';

const SignOutButton = () =>
  <button
    className="pa2 input-reset bg-transparent bn white fw2" 
    type="button"
    onClick={auth.doSignOut}
  >Sign Out</button>

export default SignOutButton;
