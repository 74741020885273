import React, { Component } from 'react'
import SiteHeader from '../components/site-header'
import withAuthorisation from '../components/withauthorisation';
//
const authCondition = (authUser) => !!authUser;

class Account extends Component {

  render() {
    return (
      <div className="pt6 pb6">
        <SiteHeader />
        <h1>Account</h1>
      </div>
    );
  }
}
//

export default withAuthorisation(authCondition)(Account);
