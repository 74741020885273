import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../../firebase'
import { convertTaskNames } from '../../../helpers'
import SiteHeader from '../../../components/site-header'
import SiteNav from '../../../components/site-nav'
import LoadingState from '../../../components/global/loading-state'
import Breadcrumbs from '../../../components/breadcrumbs'
import RevieweeDetails from '../../../components/revieweedetails'
import Modal from '../../../components/global/modal'
//
import { compose } from 'recompose'
import withAuthorisation from '../../../components/withauthorisation'
import withUser360s from '../../../components/withuser360s'
const authCondition = (authUser) => !!authUser
//

class Edit360Group extends Component {
  constructor(props) {
    super(props)

    this.onSubmitNewUser = this.onSubmitNewUser.bind(this)
    this.sendInstructions = this.sendInstructions.bind(this)
    this.closeSendingModal = this.closeSendingModal.bind(this)
    this.succesfullyCompletedEmailSendActions = this.succesfullyCompletedEmailSendActions.bind(this)
    this.exportGroup = this.exportGroup.bind(this)
    this.closeUserModal = this.closeUserModal.bind(this)
    this.openUserModal = this.openUserModal.bind(this)

    let dashboardLink
    let editLink
    let coreSettingsLink

    this.state = {
      current360: this.props.current360,
      userDetails: this.props.userDetails,
      accountTitle: this.props.accountTitle,
      accountData: this.props.accountData,
      accountId: this.props.match.params.accountId,
      loading: true,
      error: false,
      current360Id: null,
      currentGroupId: null,
      currentGroup: null,
      questionnaireSections: null,
      showNewUserModal: false,
      showSendingModal: false,
      newUserModalErrorMessage: "",
      clientLogo: "",
    }
  }
  componentDidMount() {
    window.scrollTo(0,0)
    const { match: { params } } = this.props

    this.dashboardLink = `/dashboard/${this.state.accountId}/`
    this.editLink = `/dashboard/${this.state.accountId}/360s/${params.id}/edit/`

    this.setState({
      current360Id: params.id,
      currentGroupId: params.groupId
    })
    console.log("this.state.accountId, params.id, params.groupId");
    console.log(this.state.accountId, params.id, params.groupId);
    db.doGetCurrentGroup(this.state.accountId, params.id, params.groupId).on('value', data => {
      if(data.val()) {
        this.setState({
          currentGroup: data.val()
        })

        // console.log(data.val());

        // console.log(current360.questionnaireID)
        db.getQuestionnaireSections(this.state.accountId, this.state.current360.questionnaireID).on('value', data => {
          this.setState({
            questionnaireSections: data.val(),
            loading: false
          })

          db.doGetClientLogo(params.accountId, params.id).then((logo) => {
            console.log("logo")
            console.log(logo);
            this.setState({ clientLogo: logo });
          })
        })


      } else {
        this.setState({
          // loading: false,
          error: true
        })
      }

    })
  }
  closeSendingModal () {
    this.setState({ showSendingModal: false });
  }
  exportGroup() {
    alert("Exporting!")
  }
  openUserModal() {
    this.setState({ showNewUserModal: true });
  }
  closeUserModal() {
    this.setState({ showNewUserModal: false });
  }
  onSubmitNewUser(e) {
    let userForename = this.forename.value
    let userSurname = this.surname.value
    let userEmail = this.useremail.value

    db.doCreateNonAdminUser(this.state.accountId, userForename, userSurname, userEmail).then((userData) => {
      console.log(userData)

      db.addNewUserToGroup(this.state.accountId, userData, this.state.current360Id, this.state.currentGroupId).then(() => {
        //
        this.closeUserModal()
      }).catch((error) => {
        this.setState({
          newUserModalErrorMessage: error
        })
      })
    })

    e.preventDefault()

  }
  succesfullyCompletedEmailSendActions(groupID, reminderNumber) {
    db.update360StatusCode(this.state.accountId, this.state.current360Id, 2)
    db.updateGroupTaskCompleted(this.state.accountId, this.state.current360Id, groupID, reminderNumber, this.props.userEmail)
    db.updateGroupActiveTask(this.state.accountId, this.state.current360Id, groupID, reminderNumber+1)
    if(reminderNumber < 4) {
      db.updateNextTaskDueDate(this.state.accountId, this.state.current360Id, groupID, reminderNumber+1)
    }
    this.setState({
      sendingEmails: false
    })
  }
  sendInstructions(reminderNumber) {

    this.setState({
      sendingEmails: true,
      showSendingModal: true,
    })

    // Switch templateID based on reminder number
    // let templateID
    // switch(reminderNumber) {
    //   case 1:
    //     templateID = process.env.REACT_APP_EMAILJS_SELF_REVIEW_TEMPLATE_ID
    //     break;
    //   case 2:
    //     templateID = process.env.REACT_APP_EMAILJS_SELF_REVIEW_TEMPLATE_ID_REMINDER_1
    //     break;
    //   case 3:
    //     templateID = process.env.REACT_APP_EMAILJS_SELF_REVIEW_TEMPLATE_ID_REMINDER_2
    //     break;
    //   case 4:
    //     templateID = process.env.REACT_APP_EMAILJS_SELF_REVIEW_TEMPLATE_ID_REMINDER_FINAL
    //     break;
    //   default:
    //     break;
    // }

    let mailsSent = 0

    let checkComplete = () => {
      if(mailsSent == Object.keys(this.state.currentGroup.users).length) {
        return true
      } else {
        return false
      }
    }

    // console.log(this.state.currentGroup.users)

    Object
      .keys(this.state.currentGroup.users)
      .map((key, index) => {

        // Only send to incomplete users
        if(this.state.currentGroup.users[key].completed) {
          mailsSent++
          this.succesfullyCompletedEmailSendActions(this.state.currentGroupId, reminderNumber)

        } else {
          db.assign360ToUser(this.state.accountId, key, this.state.current360Id, this.state.currentGroupId).then((data) => {

            let selfReviewLink = `https://boomerang-360.com/${this.state.accountId}/${key}/360/${this.state.current360Id}/${this.state.currentGroupId}/${this.state.current360.questionnaireID}/`
            let emailTitle = `Complete your Self Review`
            let selfReviewButtonText = `Click here to complete your self-review`
            let emailBodyCopy = `A new <strong style="font-weight: 600;">self review</strong> has been created for you!`
            console.log(`reminderNumber ${reminderNumber}`);

            if(this.state.current360.type == "Goal Tracker") {
              selfReviewLink = `${selfReviewLink}goal/${reminderNumber}/`
              emailTitle = `Complete goal ${reminderNumber}`
              emailBodyCopy = `Please complete your latest <strong style="font-weight: 600;">Goal</strong>`
              selfReviewButtonText = `Click here to complete your Goal`
            } else {
              if(reminderNumber > 1) {
                emailTitle = `Complete your Self Review - Reminder ${reminderNumber - 1}`
              }
              if(reminderNumber == 4) {
                emailTitle = `Complete your Self Review - Final Reminder`

              }
            }



            console.log(`Sending instructions to ${this.state.currentGroup.users[key].email}`)
            console.log(`Self review link = ${selfReviewLink}`);
            // Send Email!
            window.emailjs.send('mailgun', process.env.REACT_APP_EMAILJS_SELF_REVIEW_TEMPLATE_ID, {
              senderEmail: `no-reply@boomerang-360.com`,
              receiverEmail: this.state.currentGroup.users[key].email,
              selfReviewButtonText: selfReviewButtonText,
              selfReviewLink: selfReviewLink,
              emailTitle: emailTitle,
              emailBodyCopy: emailBodyCopy,
              current360Title: this.state.current360.title,
              clientLogo: this.state.accountData.logo,
            })
              .then(res => {
                console.log("SENT")
                mailsSent++
                if(checkComplete()) {
                  console.log('finished');
                  this.succesfullyCompletedEmailSendActions(this.state.currentGroupId, reminderNumber)
                }
              })
                .catch(err => {
                  // Handle errors here however you like, or use a React error boundary
                  console.error('Failed to send feedback. Error: ', err)
                  mailsSent++
                  if(checkComplete()) {
                    console.log('finished');
                    this.succesfullyCompletedEmailSendActions(this.state.currentGroupId, reminderNumber)
                  }
                })
          })
        }
      })

  }
  render() {
    const { match: { params } } = this.props

    return (
      <main>
        <SiteHeader />
        <SiteNav />
        {
          (this.state.loading) ?
            (
              <LoadingState />
            ) :
            (
              <section className="mw8-l center pa4 ph4-l">
                <Breadcrumbs
                  current360={this.state.current360}
                  userDetails={this.state.userDetails}
                  accountTitle={this.state.accountTitle}
                  links={[ {
                    title: "Dashboard",
                    to: "/dashboard/"
                  },{
                    title: this.state.accountTitle,
                    to: "../../../../../"
                  }, {
                    title: this.state.current360.title,
                    to: "../../"
                  }, {
                    title: this.state.currentGroup.title,
                    to: null
                  } ]} />

                <h1>Edit Group: { this.state.currentGroup.title }</h1>

                <div className="bg-white mv4 ph4 pv4 shadow-2 br2">

                    <div className="user-table pa2">
                      {
                        (this.state.current360.type == "360") ?
                          <h3>Reviewees</h3>
                        :
                          <h3>Users</h3>
                      }
                      <div className="flex justify-between">
                        <div className="w-60">
                          <p>This reviewee section allows you to see at a glance the status of each of the reviewees in this group. If you need to change an individuals deadline, enter the new deadline and it will update.</p>
                        </div>
                        <button className="btn"
                          disabled={ (this.state.currentGroup.activeTask != 1) ? ( "disabled" ) : '' }
                          onClick={ () => this.setState({ showNewUserModal: true }) }>Add new</button>
                      </div>


                      <div className="flex bb b--moon-gray">
                        <div className="w-30 tc br b--moon-gray"><p className="mv2"><strong>Reviewee</strong></p></div>
                        {
                          (this.state.current360.type == "360") ?
                            <div className="w-30 tc br b--moon-gray"><p className="mv2"><strong>Reviewers<br/><small>selected/completed</small></strong></p></div>
                          :
                            <div className="w-30 tc br b--moon-gray"><p className="mv2"><strong>Goal Status</strong></p></div>
                        }

                        {
                          (this.state.current360.type == "360") ?
                            <div className="w-20 tc br b--moon-gray"><p className="mv2"><strong>Deadline</strong></p></div>
                          : ''
                        }
                        <div className="w-30 tc b--moon-gray"><p className="mv2"><strong>Action</strong></p></div>
                      </div>
                      <div className="flex">
                        <div className="w-30 tc br b--moon-gray"></div>
                        {
                          (this.state.current360.type == "360") ?
                            <div className="w-30 tc br b--moon-gray">
                              <p className="flex mb0">
                                <strong className="w-25 br b--moon-gray">DR</strong>
                                <strong className="w-25 br b--moon-gray">P</strong>
                                <strong className="w-25 br b--moon-gray">O</strong>
                                <strong className="w-25">LM</strong>
                              </p>
                            </div>
                          :
                            <div className="w-30 tc br b--moon-gray">
                              <p className="flex mb0">
                                {
                                  // Loop thru sections (goals)
                                  Object
                                    .keys(this.state.questionnaireSections)
                                    .map((key, i) =>
                                      <strong className={(i==0) ? 'w-100' : 'w-100 bl b--moon-gray'}>{ i+1 }</strong>
                                    )
                                }
                              </p>
                            </div>
                        }

                        {
                          (this.state.current360.type == "360") ?
                            <div className="w-20 tc br b--moon-gray"></div>
                          : ''
                        }

                        <div className="w-30 tc b--moon-gray"></div>
                      </div>


                      {
                        (this.state.currentGroup.users) ?

                          Object
                            .keys(this.state.currentGroup.users)
                            .reverse()
                            .map(key =>
                              <RevieweeDetails
                                key={key}
                                index={key}
                                type={this.state.current360.type}
                                questionnaireId={this.state.current360.questionnaireID}
                                questionnaireSections={this.state.questionnaireSections}
                                current360Id={params.id}
                                currentGroupId={params.groupId}
                                groupUserDetails={this.state.currentGroup.users[key]}
                                projectStatusCode={this.state.current360.statusCode}
                                current360={this.state.current360}
                                userDetails={this.state.userDetails}
                                accountTitle={this.state.accountTitle}
                                accountId={this.state.accountId}
                              />
                            )

                        : ''

                      }

                      { (this.state.currentGroup.activeTask != 1) ? <p>Users cannot be added to a group once Instruction have been sent</p> : '' }






                    </div>



                    <div className="user-tasks pa2">
                      <h3>Tasks</h3>
                      <div className="flex">
                        <div className="w-30 br b--moon-gray"><p className="mv2"><strong>Task</strong></p></div>
                        {
                          (this.state.current360.type == "360") ?
                            <div className="w-20 tc br b--moon-gray"><p className="mv2"><strong>Due</strong></p></div>
                          : ''
                        }
                        {
                          (this.state.current360.type == "360") ?
                            <div className="w-20 tc br b--moon-gray"><p className="mv2"><strong>Perfomed</strong></p></div>
                          : ''
                        }
                        <div className="w-30 tc"><p className="mv2"><strong>Actioned By</strong></p></div>
                      </div>

                      {
                        (this.state.current360.type == "360") ?


                          (this.state.currentGroup.tasks) ?
                            Object
                              .keys(this.state.currentGroup.tasks)
                              .map((key, index) => {
                                return (
                                  <div key={key} index={key} className="flex items-center">
                                    <div className="w-30 br b--moon-gray">
                                      <p className="mv2">
                                        <button
                                          disabled={ (this.state.currentGroup.activeTask != key || !this.state.currentGroup.users) ? ( "disabled" ) : '' }
                                          className="f7 link ph4 pv3 dib white bg-blue br2 bn tc"
                                          onClick={ () => this.sendInstructions(parseInt(key)) }>{ convertTaskNames(key) }
                                        </button>
                                      </p>
                                    </div>
                                    <div className="w-20 tc br b--moon-gray">
                                      <p className="mv2">
                                        <strong>
                                          { (this.state.currentGroup.tasks[key].dueDate) ? ( new Date(this.state.currentGroup.tasks[key].dueDate).toLocaleDateString("en-GB") ) : '-' }
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="w-20 tc br b--moon-gray">
                                      <p className="mv2">
                                        <strong>
                                          { (this.state.currentGroup.tasks[key].completedDate) ? ( new Date(this.state.currentGroup.tasks[key].completedDate).toLocaleDateString("en-GB") ) : '-' }
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="w-30 tc">
                                      <p className="mv2">
                                        <strong>
                                          { (this.state.currentGroup.tasks[key].user) ? ( this.state.currentGroup.tasks[key].user ) : '-' }
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                )
                              }
                            )
                          : ''


                        :


                          (this.state.questionnaireSections) ?
                            Object
                              .keys(this.state.questionnaireSections)
                              .map((key, index) => {
                                let sectionTaskNum = index+1
                                return (
                                  <div key={key} index={key} className="flex items-center">
                                    <div className="w-30 br b--moon-gray">
                                      <p className="mv2">
                                        <button
                                          disabled={ (this.state.currentGroup.activeTask != sectionTaskNum || !this.state.currentGroup.users) ? ( "disabled" ) : '' }
                                          className="f7 link ph4 pv3 dib white bg-blue br2 bn tc"
                                          onClick={ () => this.sendInstructions(sectionTaskNum) }>Send Goal { sectionTaskNum }
                                        </button>
                                      </p>
                                    </div>
                                    <div className="w-30 tc">
                                      <p className="mv2">
                                        <strong>
                                          { (this.state.questionnaireSections[key].user) ? ( this.state.questionnaireSections[key].user ) : '-' }
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                )
                              }
                            )
                          : ''


                      }



                    </div>

                  </div>




                  <Modal
                    isOpen={this.state.showNewUserModal}
                    contentLabel="Add a new User to this Group"
                    onClose={ () => this.setState({ showNewUserModal: false }) }
                    content={
                      <div>
                        <h2>Add a new User to this Group</h2>
                        <form className="mw6 center pt2 pb2" onSubmit={this.onSubmitNewUser} >
                          <div className="pv3">
                            <label htmlFor="forename" className="f6 b db mb2">First Name</label>
                            <input required ref={(input) => this.forename = input} aria-describedby="forename-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="text" placeholder="Joe" />
                          </div>
                          <div className="pv3">
                            <label htmlFor="surname" className="f6 b db mb2">Last Name</label>
                            <input required ref={(input) => this.surname = input} aria-describedby="surname-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="text" placeholder="Blogs" />
                          </div>
                          <div className="pv3">
                            <label htmlFor="useremail" className="f6 b db mb2">Email</label>
                            <input required ref={(input) => this.useremail = input} aria-describedby="useremail-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="email" placeholder="email@example.com" />
                          </div>
                          <button className="b ph3 pv2 input-reset ba b--black bg-transparent pointer f6 dib" type="submit">Add User</button>
                        </form>
                        <p className="red">{ this.state.newUserModalErrorMessage }</p>
                      </div>
                    }
                  />

                  <Modal
                    isOpen={this.state.showSendingModal}
                    contentLabel="Preparing to send 360 to selected group"
                    onClose={ () => this.setState({ deleting: false }) }
                    hideCloseButton={true}
                    content={
                      <div className="tc">
                        {
                          (this.state.sendingEmails) ?
                            <h1 className="mv0">Preparing to send 360 to selected group</h1>
                          : <h1 className="mv0">Notifications Sent</h1>
                        }

                        {
                          (this.state.sendingEmails) ?
                            <p>Please wait</p>

                          : (
                            <div className="pt4">
                              <button className="f7 link ph4 pv3 dib white bg-blue br2 bn tc" onClick={this.closeSendingModal}>Close</button>
                            </div>
                          )
                        }
                      </div>
                    }
                  />


              </section>
            )
        }
      </main>
    )
  }
}

//
// export default withAuthorisation(authCondition)(Edit360Group);
export default compose(
  withAuthorisation(authCondition),
  withUser360s()
)(Edit360Group)
