import React, { Component } from 'react';
import { compose } from 'recompose'
import { db, firebase } from '../firebase'
import withAuthorisation from '../components/withauthorisation';
import withUser360s from '../components/withuser360s';
import { Link } from 'react-router-dom'
import List360 from '../components/list360'
import SiteHeader from '../components/site-header'
import SiteNav from '../components/site-nav'
import SiteFooterBrand from '../components/site-footer-brand'
import loadingGif from '../loading.gif'

const authCondition = (authUser) => !!authUser

class SelectAccount extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    db.doGetLoggedInUserDetails(firebase.auth.currentUser.uid).then((data) => {
      let userDetails = data.val()
      if(userDetails.accounts.length > 1) {
        // Display account selector!
      } else {
        // Forward to account dashboard
        this.props.history.push(`/dashboard/${userDetails.accounts[0].id}/`)
      }
    })
  }
  render() {
    return (
      <div></div>
    )
  }
}
//

export default withAuthorisation(authCondition)(SelectAccount);

// export default compose(
//   withAuthorisation(authCondition),
//   withUser360s()
// )(SelectAccount)
