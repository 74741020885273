import React, { Component } from 'react'
import Navigation from './navigation'
import AuthUserContext from './authusercontext'
import logo from '../logo.svg'

class SiteHeader extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <header className='bg-blue w-100 ph3 pv3'>
        <div className='mw9-ns ph4-ns center h-100 flex justify-between items-center'>
          {
            (this.props.userFullName) ? (
              <p className="ma0">Welcome, <strong>{this.props.userFullName}</strong></p>
            )
            : (
              <p className="ma0">Welcome to Boomerang</p>
            )
          }
          <div className="header-logo"></div>
        </div>
      </header>
    );
  }
}

export default SiteHeader
