export function convertRelationships(relationship) {
  switch(relationship) {
    case "DR":
      return "Direct Report"
      break;
    case "LM":
      return "Line Manager"
      break;
    case "P":
      return "Peer"
      break;
    case "O":
      return "Other"
      break;
    default:
      return "None"

  }
}
export function relationshipColor(relationship) {
  switch(relationship) {
    case "DR":
      return "#54b99c"
      break;
    case "LM":
      return "#dcdc00"
      break;
    case "P":
      return "#5b4898"
      break;
    case "O":
      return "#1c9ad7"
      break;
    default:
      return "#FFFFFF"

  }
}

export function average(array){
  return array.reduce((a, b) => a + b) / array.length;
}


export function convertTaskNames(taskNum) {
  switch(taskNum) {
    case "1":
      return "Send instructions"
      break;
    case "2":
      return "Send first reminders"
      break;
    case "3":
      return "Send second reminders"
      break;
    case "4":
      return "Send final reminders"
      break;
    default:
      return "Default"

  }
}


export function getUnique(arr, comp) {

  const unique = arr
       .map(e => e[comp])

     // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

   return unique;
}

export function difference(i, v){
    return i - v;
}
