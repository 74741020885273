import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SiteHeader from '../components/site-header'
import { compose } from 'recompose'
import withSuperUser from '../components/withsuperuser'
const authCondition = (authUser) => !!authUser
//

class Admin extends Component {
  render() {
    return (
      <main>
        <SiteHeader />

        <section className="mw8-l center pa4 ph4-l">
          <h1>Super Admin</h1>
          <p><Link className="btn" to="/signup/">View Accounts</Link></p>
          <p><Link className="btn" to="/signup/">Create New Account</Link></p>
        </section>
      </main>
    );
  }
}

export default compose(
  withSuperUser(authCondition),
)(Admin)
