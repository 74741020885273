import React, { Component } from 'react'
import Navigation from './navigation'

class SiteNav extends Component {

  render() {
    return (
      <header className='bg-dark-blue w-100 ph3 pv3'>
        <Navigation />
      </header>
    );
  }
}

export default SiteNav
