import React, { Component } from 'react'
import { db } from '../firebase'
// import QuestionnaireSectionAccordian from '../components/questionnairesectionaccordian'
import { Link } from 'react-router-dom'
import Dropdown from '../components/global/dropdown'
import Modal from '../components/global/modal'



class QuestionnaireSectionEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      accountId: this.props.accountId,
      current360: this.props.current360,
      userDetails: this.props.userDetails,
      accountTitle: this.props.accountTitle,
      current360Id: this.props.current360Id,
      questionnaireID: this.props.questionnaireID,
      duplicateSectionModalOpen: false,
      duplicatingSectionId: null,
      questionnaireSections: null,
      loading: true
    }

    this.deleteSection = this.deleteSection.bind(this)
    this.fireSectionAction = this.fireSectionAction.bind(this)
    this.onDuplicateSection = this.onDuplicateSection.bind(this)
    this.openDuplicateSectionModal = this.openDuplicateSectionModal.bind(this)

  }

  componentDidMount() {
    db.doGetSectionsList(this.state.accountId, this.state.questionnaireID).on('value', data => {
      console.log(data.val())
      if( !data.val() || !data.val().sections ) {
        this.setState({
          loading: false,
          questionnaireSections: null
        })
      } else {
        this.setState({
          loading: false,
          questionnaireSections: data.val().sections
        })

        let populatedSections = 0

        if( Object.keys(data.val().sections).length > 1 ) {
          Object
            .keys(data.val().sections)
            .map((key,index) => {
              if(data.val().sections[key].questions) {
                console.log(Object.keys(data.val().sections[key].questions).length)
                if(Object.keys(data.val().sections[key].questions).length > 0) {
                  populatedSections++
                }
              }
            })
        }

        // set StatusCode based on number of populated sections
        // (2 or more = statusCode: 1)
        // (less than 2 = statusCode: 0)
        let statusCode = (populatedSections > 1) ? 1 : 0

        console.log("statusCode = "+statusCode)

        db.update360StatusCode(this.state.accountId, this.state.current360Id, statusCode)

      }
    })
  }


  fireSectionAction(id, key) {
    console.log(id, key)
    // let temp = JSON.parse(JSON.stringify(sectionActions));
    // temp.forEach(item => item.selected = false);
    // temp[id].selected = true;
    // console.log(key);
    //
    switch(id) {
      case 0:
        this.openDuplicateSectionModal(key)
        break
      case 1:
        this.deleteSection(key)
        break
      default:
        return;
    }

  }

  openDuplicateSectionModal(sectionID) {
    this.setState({
      duplicatingSectionId: sectionID,
      duplicateSectionModalOpen: true
    })
  }

  onDuplicateSection(event) {
    let questionnaireRef
    let currentForm = event.currentTarget;
    db.doDuplicateQuestionnaireSection(this.state.accountId, this.state.current360.questionnaireID, this.state.duplicatingSectionId, this.title.value, this.description.value).then((data) => {
      currentForm.reset()
      this.setState({
        duplicateSectionModalOpen: false
      })
    })
    event.preventDefault();
  }

  deleteSection(sectionId) {
    db.doDeleteSection(this.state.accountId, this.state.questionnaireID, sectionId)
  }


  render() {
    if(this.state.loading) {
      return (
        <div>Loading</div>
      )
    } else {
      return (
        <div>
          <div className="accordion mw8 center mt5">

            {
              (this.state.questionnaireSections) ?
                Object
                  .keys(this.state.questionnaireSections)
                  .map((key,index) =>
                    <div key={key} className="flex justify-between items-center pa3 f6 bg-light-blue mb2">
                      <div>
                        <p className="ma0">{ this.state.questionnaireSections[key].title }<br/><small>{ this.state.questionnaireSections[key].description }</small></p>
                      </div>
                      <div>
                        <div className="dib">
                          <Dropdown
                            title="Alternate actions"
                            list={[
                              {
                                id: 0,
                                title: 'Duplicate',
                                selected: false,
                                key: key
                              },
                              {
                                id: 1,
                                title: 'Delete',
                                selected: false,
                                key: key
                              }
                            ]}
                            setTitleOnSelect={false}
                            resetThenSet={this.fireSectionAction}
                          />
                        </div>
                        {
                          /*
                          <a className="btn btn--red" onClick={ (e) => { this.deleteSection(e) } }>Delete</a>

                          */
                        }
                        <Link className="btn" key={key} to={{ pathname: `${key}/`, current360: this.state.current360, userDetails: this.state.userDetails, accountTitle: this.state.accountTitle }}>Edit</Link>
                      </div>
                    </div>

                  )
                : (
                  <p>Create a new Competency section using the buttons above</p>
                )
            }

          </div>

          <Modal
            isOpen={ this.state.duplicateSectionModalOpen }
            contentLabel="Duplicate Questionnare Section"
            onClose={ () => this.setState({ duplicateSectionModalOpen: false }) }
            content={
              <div>
                <form className="mw6 center pt2 pb2" onSubmit={this.onDuplicateSection} >
                  <h2>Duplicating Section</h2>
                  <p>Enter details for the new section - all question data will be copied across</p>
                  <label htmlFor="title" className="f7 b db mb2">Name</label>
                  <input required ref={(input) => this.title = input} aria-describedby="name-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="text" placeholder="Name your Section" />
                  <label htmlFor="description" className="f7 b db mb2">Description</label>
                  <textarea ref={(input) => this.description = input} aria-describedby="description-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" rows="6" placeholder="Give your competency a description"></textarea>
                  <button className="b ph3 pv2 mt4 input-reset ba b--black bg-transparent pointer f6 dib" type="submit">Submit</button>
                </form>
              </div>
          }/>

        </div>
      );

    }
  }
}

export default QuestionnaireSectionEditor;
